import React, { useCallback, useState } from 'react';
import ModalImage from "react-modal-image";

function ReceiptFormImages({ formState, setFormState, receiptImgUrl }) {

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormState({
            ...formState,
            [name]: files[0],
        });
    };
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6  my-6 ">
            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">Challan Image <span className="text-red-500">*</span></label>
                <input
                    type="file"
                    name="receipt_challan_img"
                    onChange={handleFileChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
                <div style={{ width: 100 }}>
                    <ModalImage
                        small={`${process.env.REACT_APP_IMG_URI}/${receiptImgUrl.receipt_challan_img}`}
                        large={`${process.env.REACT_APP_IMG_URI}/${receiptImgUrl.receipt_challan_img}`}
                        alt="Challan image"
                    />
                </div>
            </div>

            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">Weight Slip Image</label>
                <input
                    type="file"
                    name="receipt_weight_slip_img"
                    onChange={handleFileChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
                <div style={{ width: 100 }}>
                    <ModalImage
                        small={`${process.env.REACT_APP_IMG_URI}/${receiptImgUrl.receipt_weight_slip_img}`}
                        large={`${process.env.REACT_APP_IMG_URI}/${receiptImgUrl.receipt_weight_slip_img}`}
                        alt="weight slip"
                    />
                </div>
            </div>

            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">Item Image</label>
                <input
                    type="file"
                    name="receipt_item_img"
                    onChange={handleFileChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
                <div style={{ width: 100 }}>
                    <ModalImage
                        small={`${process.env.REACT_APP_IMG_URI}/${receiptImgUrl.receipt_item_img}`}
                        large={`${process.env.REACT_APP_IMG_URI}/${receiptImgUrl.receipt_item_img}`}
                        alt="item image"
                    />
                </div>
            </div>

            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">Invoice Image <span className="text-red-500">*</span></label>
                <input
                    type="file"
                    name="receipt_invoice_img"
                    onChange={handleFileChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
                <div style={{ width: 100 }}>
                    <ModalImage
                        small={`${process.env.REACT_APP_IMG_URI}/${receiptImgUrl.receipt_invoice_img}`}
                        large={`${process.env.REACT_APP_IMG_URI}/${receiptImgUrl.receipt_invoice_img}`}
                        alt="invoice image"
                    />
                </div>
            </div>

            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">Quality Checked Image</label>
                <input
                    type="file"
                    name="receipt_quality_img"
                    onChange={handleFileChange}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                />
                <div style={{ width: 100 }}>
                    <ModalImage
                        small={`${process.env.REACT_APP_IMG_URI}/${receiptImgUrl.receipt_quality_img}`}
                        large={`${process.env.REACT_APP_IMG_URI}/${receiptImgUrl.receipt_quality_img}`}
                        alt="quality image"
                    />
                </div>
            </div>

            <div className="flex flex-col">
                <label className="mb-2 font-semibold text-gray-700">Weight Kg</label>
                <input
                    type="text"
                    name="receipt_weight_kg"
                    value={formState.receipt_weight_kg}
                    onChange={(e) => { setFormState((prev) => ({ ...prev, receipt_weight_kg: e.target.value })) }}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                    placeholder="Weight Kg"
                />
            </div>
        </div>
    )
}

export default ReceiptFormImages