import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from "react-select";
import api from './Axios/api';
import { Trash2 } from 'lucide-react';
import Swal from 'sweetalert2';
import BackBtn from './common/BackBtn';
import { useSelector } from "react-redux";

const IndentEdit = () => {
    const { id } = useParams();
    const [indent, setIndent] = useState({});
    const [staffName, setStaffName] = useState([]);
    const [facLoc, setFacLoc] = useState([]);
    const [items, setItems] = useState([]);
    const [dropItem, setDropItems] = useState([]);
    const [itemGroups, setItemGroups] = useState([]);
    const [itemGroupId, setItemGroupId] = useState();
    const [showNewItemForm, setShowNewItemForm] = useState(false);
    const user_role = useSelector((state) => state.user.staff_name);

    const [newItem, setNewItem] = useState({
        item_group: '',
        item_group_id: '',
        item_title: '',
        item_name: '',
        current_stock: '',
        required_qty: '',
        place_of_use: '',
        remark: ''
    });



    const handleSaveNewItem = async () => {
        // Ensure all necessary fields are filled
        if (newItem.item_name && newItem.item_group) {
            // Add new item to the existing list
            setItems([...items, { ...newItem, _id: new Date().getTime().toString() }]);
            await api.post(`indent/add-item`, {
                indent_id: id,
                item_name: newItem.item_name,
                item_group: newItem.item_group_id,
                current_stock: newItem.current_stock,
                required_qty: newItem.required_qty,
                place_of_use: newItem.place_of_use,
                remark: newItem.remark
            }).then((res) => {
                console.log(res.data)
            })

            // Hide the new item form
            setShowNewItemForm(false);

            // Reset the new item state
            setNewItem({
                item_group: '',
                item_group_id: '',
                item_title: '',
                item_name: '',
                current_stock: '',
                required_qty: '',
                place_of_use: '',
                remark: ''
            });

            Swal.fire({
                title: "Success!",
                text: "Item added successfully.",
                icon: "success"
            });
        } else {
            Swal.fire({
                title: "Error!",
                text: "Please fill all required fields before saving.",
                icon: "error"
            });
        }
    };

    const handleShowNewItemForm = () => {
        setShowNewItemForm(true);
    };

    useEffect(() => {
        staffNames();
        facLocHandler();
        handleIndentDetails(id);
    }, [id]);

    const handleIndentDetails = (id) => {
        api.get(`/indent/get/${id}`)
            .then((res) => {
                console.log("data=>",res.data[0])
                setIndent(res.data[0]);
                setItems(res.data[0].items);
            })
            .catch((err) => console.log(err));
    }

    const staffNames = async () => {
        await api.get(`/staff/get-staff-name`)
            .then((res) => {
                const newData = res.data.map((staff) => ({
                    value: staff._id, label: staff.staff_name
                }));
                setStaffName(newData);
               
            })
            .catch((err) => console.log(err));
    }

    const getItemGroup = () => {
        api.get(`/purchase-item-category/get-name`)
            .then((res) => {
                const data = res.data;
                const newData = data.map((cat, index) => {

                    return { value: cat._id, label: cat.itemcat_title }
                })
                setItemGroups(newData)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getGroupItems = async (catId) => {
        await api.get(`${catId ? "/purchase-item/get-item-name/" + catId : "/purchase-item/get-item-name/"}`)
            .then((res) => {
                const data = res.data;
                const newData = data.map((item, index) => {
                    return { value: item._id, label: item.item_title }

                })
                setDropItems(newData)
            })
    }

    const facLocHandler = async () => {
        await api.get(`/factory-location/get-factory-location`)
            .then((res) => {
                const newData = res.data.map((location) => ({
                    value: location._id, label: location.fectory_loca_name
                }));
                setFacLoc(newData);
            })
            .catch((err) => console.log(err));
    }

    const formatDateForInput = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2);
        const day = (`0${date.getDate()}`).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const handleItemChange = (index, name, value) => {
        const newItems = [...items];
        newItems[index][name] = value;
        setItems(newItems);
    };


    const deleteItems = (deleteId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                api.delete(`/indent/delete-indent-item/${deleteId}`)
                    .then((res) => {
                        handleIndentDetails(id);
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                    })
                    .catch((err) => console.log(err))
            }
        });

    }

    const alertMsg = async () => {
        await Swal.fire({
            title: 'Item updated Successfully',
            icon: "success"
        })
    }



    const handleUpdate = () => {        
        api.patch(`/indent/update-indent/${id}`, {     
            ...((user_role === "Admin" || user_role === "Sameer Khan") && { 
                indent_by: indent.indent_by,
                indent_branch: indent.indent_branch 
            }),
            indent_date: indent.indent_date,
            indent_remark: indent.indent_remark,
        })
            .then((res) => {
                console.log('Indent updated', res.data);
                alertMsg()
            })
            .catch((err) => console.log(err));
    };

    const handleSave = (item) => {
        console.log(item.item_group_id)
        api.patch(`/indent/update-indent-item/${item._id}`, {
            item_name: item.item_name,
            item_group: item.item_group_id,
            current_stock: item.current_stock,
            required_qty: item.required_qty,
            place_of_use: item.place_of_use,
            remark: item.remark,
        })
            .then((res) => {
                console.log('Item updated', res.data);
                alertMsg()
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemGroup();
    }, [])

    useEffect(() => {
        getGroupItems(itemGroupId);
    }, [itemGroupId])

    return (
        <div className='w-full flex items-center justify-start flex-col mt-2'>
            <div className="w-11/12 h-10 px-2 flex justify-between items-center text-[#7286D3] font-bold rounded-md bg-[#fdfdfd] shadow">
                <div>Edit Indent</div>
                <BackBtn />
            </div>
            <div className='w-11/12 bg-[#fdfdfd] flex justify-between items-center mt-3 rounded flex-wrap'>
                <div className='p-2 flex flex-col items-start gap-1'>
                    <label htmlFor="indentBY">Indent by</label>
                    <Select
                        options={staffName}
                        placeholder={indent.indent_by_name}
                        onChange={(e) => setIndent((indent) => ({ ...indent, indent_by: e.value, indent_by_name: e.label }))}
                        styles={{
                            menuList: () => ({
                                overflowY: "scroll",
                                maxHeight: "200px",
                            }),
                            input: (baseStyles) => ({
                                ...baseStyles,
                                width: "180px"
                            }),
                            placeholder: (baseStyles) => ({
                                ...baseStyles,
                                color: "black"
                            })
                        }}
                    />
                </div>
                <div className='p-2 flex flex-col items-start gap-1'>
                    <label htmlFor="indentBY">Branch</label>
                    <Select
                        options={facLoc}
                        placeholder={indent.indent_branch_name}
                        onChange={(e) => setIndent((indent) => ({ ...indent, indent_branch: e.value, indent_branch_name: e.label }))}
                        styles={{
                            menuList: () => ({
                                overflowY: "scroll",
                                maxHeight: "200px",
                            }),
                            input: (baseStyles) => ({
                                ...baseStyles,
                                width: "180px"
                            }),
                            placeholder: (baseStyles) => ({
                                ...baseStyles,
                                color: "black"
                            })
                        }}
                    />
                </div>
                <div className='p-2 flex flex-col items-start gap-1'>
                    <label htmlFor="date">Date</label>
                    <input
                        type="date"
                        name="date"
                        id="date"
                        className="block rounded outline-none border-1 py-1.5 pl-7  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                        value={formatDateForInput(indent.indent_date)}
                        onChange={(e) => setIndent((indent) => ({ ...indent, indent_date: e.target.value }))}
                    />
                </div>
                <div className='p-2 flex flex-col items-start gap-1'>
                    <label htmlFor="remark">Remark</label>
                    <input
                        type="text"
                        name="remark"
                        id="remark"
                        className="block rounded outline-none border-1 py-1.5 pl-7  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                        value={indent.indent_remark}
                        onChange={(e) => setIndent((indent) => ({ ...indent, indent_remark: e.target.value }))}
                    />
                </div>
                <button
                                className='p-2 m-2 bg-[#7E8EF1] text-white rounded'
                                onClick={() => handleUpdate()}
                            >
                                Update
                            </button>
            </div>
            {/* 2nd layer */}

            <div className='w-11/12 bg-[#fdfdfd] flex flex-col mt-3 justify-between items-center'>
                {items.length > 0 && items.map((item, index) => (
                    <div className='w-full justify-between items-center flex flex-wrap' key={item._id}>
                        <div className='flex flex-col items-start justify-center p-3'>
                            <label htmlFor="itemName">Item Group</label>
                            <Select
                                placeholder={item.item_group}
                                value={item.item_group}

                                options={itemGroups}
                                // onChange={(e) => setItems((prev) => ({ ...prev[index]["item_group"], item_group: e.value }))}
                                onChange={(e) => {
                                    handleItemChange(index, "item_group", e.label);
                                    handleItemChange(index, "item_group_id", e.value)
                                    setItemGroupId(e.value)
                                }}
                                styles={{
                                    menuList: () => ({
                                        overflowY: "scroll",
                                        maxHeight: "200px",
                                    }),
                                    input: (baseStyles) => ({
                                        ...baseStyles,
                                        width: "180px"
                                    }),
                                    placeholder: (baseStyles) => (
                                        {
                                            ...baseStyles,
                                            color: "black"
                                        }
                                    )
                                }}
                            />
                        </div>
                        <div className='flex flex-col items-start justify-center p-3'>
                            <label htmlFor="itemName">Item Name</label>
                            <Select
                                options={dropItem}

                                placeholder={item.item_title?.length > 15 ? `${item.item_title?.slice(0, 20)}....` : item.item_title}
                                value={item.item_title}
                                onChange={(e) => {
                                    handleItemChange(index, "item_title", e.label)
                                    handleItemChange(index, "item_name", e.value)
                                }}
                                styles={{
                                    menuList: () => ({
                                        overflowY: "scroll",
                                        maxHeight: "200px",
                                    }),
                                    input: (baseStyles) => ({
                                        ...baseStyles,
                                        width: "180px"
                                    }),
                                    placeholder: (baseStyles) => (
                                        {
                                            ...baseStyles,
                                            color: "black"
                                        }
                                    )
                                }}
                            />
                        </div>
                        <div className='flex flex-col items-start justify-center p-3'>
                            <label htmlFor="currStock">Curr Stock</label>
                            <input
                                type="number"
                                className="block rounded outline-none border-1 py-1.5 pl-2  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                                value={item.current_stock}
                                onChange={(e) => handleItemChange(index, 'current_stock', e.target.value)}
                            />
                        </div>
                        <div className='flex flex-col items-start justify-center p-3'>
                            <label htmlFor="reqQty">Req Qty</label>
                            <input
                                type="number"
                                className="block rounded outline-none border-1 py-1.5 pl-2  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                                value={item.required_qty}
                                onChange={(e) => handleItemChange(index, 'required_qty', e.target.value)}
                            />
                        </div>
                        <div className='flex flex-col items-start justify-center p-3'>
                            <label htmlFor="placeOfUse">Place of Use</label>
                            <input
                                type="text"
                                className="block rounded outline-none border-1 py-1.5 pl-2  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                                value={item.place_of_use}
                                onChange={(e) => handleItemChange(index, 'place_of_use', e.target.value)}
                            />
                        </div>
                        <div className='flex flex-col items-start justify-center p-3'>
                            <label htmlFor="remark">Remark</label>
                            <input
                                type="text"
                                className="block rounded outline-none border-1 py-1.5 pl-2  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                                value={item.remark}
                                onChange={(e) => handleItemChange(index, 'remark', e.target.value)}
                            />
                        </div>
                        <div className='flex justify-center items-center'>
                            <button
                                className='p-2 m-2 bg-[#7E8EF1] text-white rounded'
                                onClick={() => handleSave(item)}
                            >
                                Update
                            </button>
                            <button
                                className='p-2 m-2 bg-red-500 text-white rounded'
                                onClick={() => {
                                    // const newItems = items.filter((_, i) => i !== index);
                                    // setItems(newItems);
                                    deleteItems(item._id)
                                }}
                            >
                                <Trash2 />
                            </button>
                        </div>
                        <hr className='underline w-full ' />
                    </div>

                ))}
                <div className='w-full flex justify-end p-3'>
                    {
                        !showNewItemForm ?
                            <button
                                className='p-2 m-2 bg-[#4CAF50] text-white rounded'
                                onClick={handleShowNewItemForm}>
                                Add
                            </button> :

                            <div className='w-full justify-between items-center flex flex-wrap' >
                                <div className='flex flex-col items-start justify-center p-3'>
                                    <label htmlFor="itemName">Item Group</label>
                                    <Select
                                        placeholder="Select Group"
                                        options={itemGroups}
                                        // onChange={(e) => setItems((prev) => ({ ...prev[index]["item_group"], item_group: e.value }))}
                                        onChange={(e) => setNewItem(prev => ({ ...prev, item_group: e.label, item_group_id: e.value }))}
                                        styles={{
                                            menuList: () => ({
                                                overflowY: "scroll",
                                                maxHeight: "200px",
                                            }),
                                            input: (baseStyles) => ({
                                                ...baseStyles,
                                                width: "180px"
                                            }),
                                            placeholder: (baseStyles) => (
                                                {
                                                    ...baseStyles,
                                                    color: "black"
                                                }
                                            )
                                        }}
                                    />
                                </div>
                                <div className='flex flex-col items-start justify-center p-3'>
                                    <label htmlFor="itemName">Item Name</label>
                                    <Select
                                        options={dropItem}
                                        placeholder="Select Item"
                                        onChange={(e) => setNewItem(prev => ({ ...prev, item_title: e.label, item_name: e.value }))}
                                        styles={{
                                            menuList: () => ({
                                                overflowY: "scroll",
                                                maxHeight: "200px",
                                            }),
                                            input: (baseStyles) => ({
                                                ...baseStyles,
                                                width: "180px"
                                            }),
                                            placeholder: (baseStyles) => (
                                                {
                                                    ...baseStyles,
                                                    color: "black"
                                                }
                                            )
                                        }}
                                    />
                                </div>
                                <div className='flex flex-col items-start justify-center p-3'>
                                    <label htmlFor="currStock">Curr Stock</label>
                                    <input
                                        type="number"
                                        className="block rounded outline-none border-1 py-1.5 pl-2  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                                        value={newItem.current_stock}
                                        onChange={(e) => setNewItem(prev => ({ ...prev, current_stock: e.target.value }))}
                                    />
                                </div>
                                <div className='flex flex-col items-start justify-center p-3'>
                                    <label htmlFor="reqQty">Req Qty</label>
                                    <input
                                        type="number"
                                        className="block rounded outline-none border-1 py-1.5 pl-2  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                                        value={newItem.required_qty}
                                        onChange={(e) => setNewItem(prev => ({ ...prev, required_qty: e.target.value }))}
                                    />
                                </div>
                                <div className='flex flex-col items-start justify-center p-3'>
                                    <label htmlFor="placeOfUse">Place of Use</label>
                                    <input
                                        type="text"
                                        className="block rounded outline-none border-1 py-1.5 pl-2  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                                        value={newItem.place_of_use}
                                        onChange={(e) => setNewItem(prev => ({ ...prev, place_of_use: e.target.value }))}
                                    />
                                </div>
                                <div className='flex flex-col items-start justify-center p-3'>
                                    <label htmlFor="remark">Remark</label>
                                    <input
                                        type="text"
                                        className="block rounded outline-none border-1 py-1.5 pl-2  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                                        value={newItem.remark}
                                        onChange={(e) => setNewItem(prev => ({ ...prev, remark: e.target.value }))}
                                    />
                                </div>
                                <div className='flex justify-center items-center'>
                                    <button
                                        className='p-2 m-2 bg-[#7E8EF1] text-white rounded'
                                        onClick={handleSaveNewItem}
                                    >
                                        Save
                                    </button>
                                    {/* <button
                                        className='p-2 m-2 bg-red-500 text-white rounded'
                                        onClick={() => {
                                            // const newItems = items.filter((_, i) => i !== index);
                                            // setItems(newItems);
                                            // deleteItems(item._id)
                                        }}
                                    >
                                        <Trash2 />
                                    </button> */}
                                </div>
                                <hr className='underline w-full ' />
                            </div>

                    }

                </div>
            </div>

        </div>
    );
};

export default IndentEdit;

