import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useLocation, useNavigate } from "react-router-dom";
import api from "./Axios/api";
import Select from 'react-select';
import Swal from 'sweetalert2';
import BackBtn from "./common/BackBtn";
import { Discount } from "@mui/icons-material";
import { useSelector } from "react-redux";
const CreatePOForm = () => {
  const user_branch = useSelector((state) => state.user.staff_FacLoc);
  const location = useLocation();
  const { ids } = location.state; // it use for create po details
  const { poId } = location.state; // it is use for edit po details
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  // console.log(data);
  const [voucher, setVoucher] = useState([]);
  const [purchase_ledger, setPurchase_ledger] = useState([]);
  const [purchaseDataArr, setPurchaseDataArr] = useState([]);

  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [tempQuantity, setTempQuantity] = useState("");
  const [loadingRowIndex, setLoadingRowIndex] = useState(null);

  const handleDoubleClick = (rowIndex, value) => {
    setEditingRowIndex(rowIndex);
    setTempQuantity(value);
  };

  const handleQuantityChange = (value) => {
    setTempQuantity(value);
  };

  const handleSave = async (rowIndex) => {
    setLoadingRowIndex(rowIndex);
    try {
      await api.patch(`/purchase/edit-item-quantity/${poId}`, {
        purchase_qty: tempQuantity
      });
      setData((prevData) =>
        prevData.map((row, index) =>
          index === rowIndex ? { ...row, purchase_qty: tempQuantity } : row
        )
      );
      Swal.fire({
        icon: 'success',
        title: 'Updated!',
        text: 'Quantity updated successfully.',
        timer: 1500,
      });
    }
    catch (error) {
      console.error("Error updating quantity:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to update quantity.',
      });
    }

    setLoadingRowIndex(null);
    setEditingRowIndex(null);
  };


  const [formState, setFormState] = useState({
    party_id: "",
    po_number: '',
    po_date: '',
    dispatch_through: '',
    destination: '',
    fright: '',
    purchase_ledger: 'BF Slag Purchase',
    purchase_ledger_name: "",
    terms_of_payment: '',
    voucher_type: 'Select Voucher Type',
    voucher_type_name: "",
    delivery_terms: ''
  });

  const getEditData = async (id) => {
    try {
      const res = await api.get(`/purchase/get-approved-item-detail/?poId=${id}`);
      setData(res.data.purchase);
      console.log("get-approved-item-detail-id", res.data);
      setFormState({
        party_id: res.data.party_id,
        po_number: res.data.po_number,
        po_date: res.data.po_date.split('T')[0], // Extract date part only
        dispatch_through: res.data.dispatch_through,
        destination: res.data.destination,
        fright: res.data.fright,
        purchase_ledger: res.data.purchase_ledger,
        purchase_ledger_name: res.data.purchase_ledger_name,
        terms_of_payment: res.data.terms_of_payment,
        voucher_type: res.data.voucher_type,
        voucher_type_name: res.data.voucher_type_name,
        delivery_terms: res.data.delivery_terms
      });

    } catch (error) {
      console.log(error);
    }
  };

  const getApproveData = async (ids) => {
    try {
      const res = await api.get(`/purchase/get-approved-item-detail/${ids}`);
      setData(res.data);
      console.log("get-approved-item-detail-ids", res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getVoucher = async () => {
    try {
      const res = await api.get(`/voucher-type/get`);
      const data = res.data;
       
      const filteredData = data.filter((item) => item.voucher_factory === user_branch);
      const newData = filteredData.map((data, index) => ({
        label: data.voucher_type,
        value: data._id,
      }))
      // console.log("voucher", filteredData);
      setVoucher(newData)
    } catch (error) {
      console.log(error)
    }
  }

  

  const getpurchase_ledger = async () => {
    try {
      const res = await api.get(`/purchase-ledger/get-all`);
      const data = res.data;
      console.log("ledger", data.purchaseLedger);

      const newData = data.purchaseLedger.map((data, index) => ({
        label: data.purchase_ledger_name,
        value: data._id,
      }))
      setPurchase_ledger(newData);

    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    console.log("New Data", data);
    const newData = data.map((data, index) => ({
      purchase_id: data._id,
      stock_desc: data.stock_desc
    }))
    setPurchaseDataArr(newData)
    setFormState(prev => ({ ...prev, party_id: data[0]?.party }))
  }, [data])


  useEffect(() => {
    getVoucher()
    getpurchase_ledger()
  }, [])

  useEffect(() => {
    ids && getApproveData(ids.join(","));
    poId && getEditData(poId);
  }, [ids, poId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value
    });
  };

  const validateForm = () => {
    const { po_number, po_date, dispatch_through, destination, purchase_ledger, terms_of_payment, voucher_type, delivery_terms } = formState;
    if (!po_number || !po_date || !dispatch_through || !destination || !purchase_ledger || !terms_of_payment || !voucher_type || !delivery_terms) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please fill all the required fields marked with *',
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      let res;
      if (poId) {
        res = await api.patch(`/po-detail/update/${poId}`, {
          purchaseDataArr, ...formState
        });
      } else {
        res = await api.post(`/po-detail/add`, {
          purchaseDataArr, ...formState
        });
      }
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Purchase order created successfully',
        timer: 1500,
      });
      navigate("/created-po-list");
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while creating the purchase order',
      });
    }
  };

  const handleReset = () => {
    setFormState({
      po_number: '',
      po_date: '',
      dispatch_through: '',
      destination: '',
      fright: '',
      purchase_ledger: 'BF Slag Purchase',
      terms_of_payment: '',
      voucher_type: 'Select Voucher Type',
      delivery_terms: ''
    });
  };

  const handleStockDescChange = useCallback((rowIndex, value) => {
    setData((prevData) =>
      prevData.map((row, index) =>
        index === rowIndex ? { ...row, stock_desc: value } : row
      )
    );
  }, []);

  const handleKeyDown = useCallback((e, type, rowIndex) => {
    if (e.key == "Enter") {
      const updateValue = `${e.target.value};`
      if (type == "stock") {
        handleStockDescChange(rowIndex, updateValue);
      } else if (type = "delivery") {
        setFormState((prev) => ({ ...prev, delivery_terms: updateValue }))
      }
    }
  }, [handleStockDescChange])

  const columns = useMemo(
    () => [
      {
        accessorKey: "item_title",
        header: "ITEM NAME",
        size: 100,
      },
      {
        accessorKey: "purchase_qty",
        header: "QUANTITY",
        size: 50,
        Cell: ({ row }) => {
          const isEditing = row.index === editingRowIndex;
          const isLoading = row.index === loadingRowIndex;
          return isEditing ? (
            <div className="flex items-center gap-2">
              <input
                type="number"
                value={tempQuantity}
                onChange={(e) => handleQuantityChange(e.target.value)}
                className="w-16 border border-gray-300 p-1 text-center"
                autoFocus
              />
              <button
                onClick={() => handleSave(row.index, row.original._id)}
                className={`px-2 py-1 rounded ${isLoading ? "bg-gray-400" : "bg-green-500 text-white"
                  }`}
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? "Saving..." : "Done"}
              </button>
            </div>
          ) : (
            <div
              onDoubleClick={() => handleDoubleClick(row.index, row.original.purchase_qty)}
              className="cursor-pointer"
            >
              {row.original.purchase_qty}
            </div>
          );
        },
      },
      {
        accessorKey: "item_unit",
        header: "Unit",
        size: 20,
      },
      {
        accessorKey: "discount",
        header: "Dis (%)",
        size: 20,
      },
      {
        accessorKey: "total_without_gst",
        header: "Total (Without Gst)",
        Cell: ({ cell }) => `₹${cell.getValue()}`,
        size: 70,
      },
      {
        accessorKey: "item_gst",
        header: "GST (%)",
        size: 30,
      },
      {
        accessorKey: "total_with_gst",
        header: "Total (With Gst)",
        Cell: ({ cell }) => `₹${cell.getValue()}`,
        size: 50,
      },
      {
        accessorKey: "stock_desc",
        header: "Stock Description",
        size: 100,
        Cell: ({ row, cell }) => (
          <textarea
            type="text"
            value={cell.getValue() || ''}
            onChange={(e) => handleStockDescChange(row.index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, "stock", row.index)}
            className="block w-full rounded border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        )
      },
    ],
    [handleStockDescChange, data, editingRowIndex, tempQuantity]
  );

  const returnHandler = async (ids) => {
    try {
      const joinId = ids.join(",");
      // console.log(joinId)
      const res = await api.patch(`/purchase/return-item/${joinId}`);
      console.log("return-item-detail", res.data);
      navigate(`/returnList`)
    } catch (error) {
      console.log(error)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    renderToolbarAlertBannerContent: (tableInstance) => {
      const selectedRowIds = Object.keys(tableInstance.table.getState().rowSelection);
      const selectedRows = selectedRowIds.map(id => tableInstance.table.getRow(id).original);
      const ids = selectedRows.map((item) => item.indent_item_id)
      return (
        <div className='flex items-center gap-3'>
          {tableInstance.selectedAlert}
          <div className='text-sm text-[#36C2CE] cursor-pointer'
            onClick={(e) => {
              returnHandler(ids)
            }}
          >
            Return
          </div>
        </div>
      )
    }
  });

  return (
    <div className="flex-1 px-5 w-full justify-center items-center">

      <div className="h-10 px-2 flex justify-between items-center text-violet-500 font-bold rounded-md bg-[#fdfdfd] shadow my-2">
        <div className="text-lg" onClick={() => console.log(data)}>Create PO Form</div>
        <BackBtn />
      </div>
      <div className="w-full min-h-[500px] rounded-md shadow">
        <MaterialReactTable table={table} />
        <div className="bg-[#fdfdfd] border mt-2 p-2 flex w-full justify-between items-center rounded-md shadow">
          <div className="p-4 flex rounded-md items-center w-full min-h-16">
            <div className="w-full">
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
                  <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                      Voucher Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="po_number"
                      value={formState.po_number}
                      onChange={handleChange}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter Vch no."
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                      Voucher Date <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="date"
                      name="po_date"
                      value={formState.po_date}
                      onChange={handleChange}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                      Dispatch Through <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="dispatch_through"
                      value={formState.dispatch_through}
                      onChange={handleChange}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter dispatch details"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                      Destination <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="destination"
                      value={formState.destination}
                      onChange={handleChange}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter destination"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                      Other reference
                    </label>
                    <input
                      type="text"
                      name="fright"
                      value={formState.fright}
                      onChange={handleChange}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter fright"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                      Purchase Ledger <span className="text-red-500">*</span>
                    </label>

                    <Select
                      name="purchase_ledger"
                      options={purchase_ledger}
                      placeholder={formState.purchase_ledger_name || "please select..."}
                      // value={formState.purchase_ledger_name}
                      value={purchase_ledger.find(
                        (option) => option.value === formState.purchase_ledger
                      )}
                      onChange={(selectedOption) => {
                        // console.log("Selected Data:", selectedOption); 
                        setFormState({
                          ...formState,
                          purchase_ledger: selectedOption.value,
                          purchase_ledger_name: selectedOption.label,
                        });
                      }}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                      Terms of Payment <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="terms_of_payment"
                      value={formState.terms_of_payment}
                      onChange={handleChange}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter terms of payment"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                      Voucher Type <span className="text-red-500">*</span>
                    </label>
                    <Select
                      name="voucher_type"
                      options={voucher}
                      placeholder={formState.voucher_type_name || "Please select...."}
                      value={formState.voucher_type_name}
                      onChange={(selectedOption) => setFormState({ ...formState, voucher_type: selectedOption.value, voucher_type_name: selectedOption.label })}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                      Delivery Terms <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      type="text"
                      name="delivery_terms"
                      value={formState.delivery_terms}
                      onKeyDown={(e) => { handleKeyDown(e, "delivery") }}
                      onChange={handleChange}
                      className="block w-full rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter delivery terms"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-end space-x-4 mt-4">
                  <button
                    type="button"
                    onClick={handleReset}
                    className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded"
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePOForm;
