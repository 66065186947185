// ItemForm.js
import React, { useCallback } from "react";

const ItemForm = ({
    inputs,
    setInputs,
    append,
    setAppend,
    editId,
    factoryLocation,
    categoryArray,
    brandArray,
    addItem,
    editItem,
    resetHandler,
}) => {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs((prev) => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (e, stateKey, appendKey) => {
        const { value, selectedOptions } = e.target;
        setInputs((prev) => ({ ...prev, [stateKey]: value }));
        if (appendKey && selectedOptions.length > 0) {
            setAppend((prev) => ({ ...prev, [appendKey]: selectedOptions[0].text }));
        }
    };

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            if (editId) {
                editItem(e, editId);
            } else {
                addItem(e);
            }
        },
        [editId, addItem, editItem]
    );

    return (
        <div className="bg-white p-6 rounded-lg shadow-md w-[33rem]">
            <h2 className="text-xl font-semibold mb-4">{editId ? "Edit Item" : "Item Form"}</h2>
            <form className="space-y-4" onSubmit={handleSubmit}>
                {/* Factory Dropdown */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Factory<span className="text-red-600">*</span>
                    </label>
                    <select
                        onChange={(e) => handleSelectChange(e, "factory", "factory")}
                        value={inputs.factory}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Select Factory</option>
                        {factoryLocation.map((factory) => (
                            <option key={factory._id} value={factory._id}>
                                {factory.fectory_loca_name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Item Category Dropdown */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Item Category<span className="text-red-600">*</span>
                    </label>
                    <select
                        onChange={(e) => handleSelectChange(e, "item_category", "category")}
                        value={inputs.item_category}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Select Item Category</option>
                        {categoryArray.map((category) => (
                            <option key={category._id} value={category._id}>
                                {category.itemcat_title}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Item Brand Dropdown */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Item Brand<span className="text-red-600">*</span>
                    </label>
                    <select
                        onChange={(e) => handleSelectChange(e, "item_brand", "brand")}
                        value={inputs.item_brand}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Select Item Brand</option>
                        {brandArray.map((brand) => (
                            <option key={brand._id} value={brand._id}>
                                {brand.brand_name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Item Title */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Item Title<span className="text-red-600">*</span>
                    </label>
                    <input
                        type="text"
                        name="item_title"
                        placeholder="Enter Item Title"
                        onChange={handleInputChange}
                        value={inputs.item_title}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>

                {/* Unit */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Unit<span className="text-red-600">*</span>
                    </label>
                    <input
                        type="text"
                        name="unit"
                        placeholder="Enter Unit"
                        onChange={handleInputChange}
                        value={inputs.unit}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>

                {/* GST */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        GST (in %)
                    </label>
                    <input
                        type="text"
                        name="gst"
                        placeholder="Enter GST"
                        onChange={handleInputChange}
                        value={inputs.gst}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>

                {/* Submit and Reset Buttons */}
                <div className="flex gap-4">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                    >
                        Submit
                    </button>
                    <button
                        type="reset"
                        onClick={resetHandler}
                        className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                    >
                        Reset
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ItemForm;