import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "./Axios/api"
import Swal from "sweetalert2";
import { Trash2 } from "lucide-react";
import BackBtn from "./common/BackBtn";

const dt = new Date();
const ConsumptionForm = () => {
  const [formData, setFormData] = useState({
    date: dt.toISOString().split("T")[0],
    voucherNo: "",
    godown: "",
    godown_name: "",
    quantity: "",
    rate: "",
    item: "",
    item_name: "",
    amount: "",
    narration: "",
  });
  const [totalData, setTotalData] = useState();
  const [finalData, setFinalData] = useState([]);
  const [facatory, setFactory] = useState([]);
  const [item, setItems] = useState([]);
  const [errors, setErrors] = useState({});



  const validateForm = () => {
    const newErrors = {};

    if (!formData.date) newErrors.date = "Date is required.";
    if (!formData.voucherNo) newErrors.voucherNo = "Voucher No. is required.";
    if (!formData.godown) newErrors.godown = "Godown is required.";
    if (!formData.item) newErrors.item = "Item is required.";
    if (!formData.quantity) newErrors.quantity = "Quantity is required.";
    if (!formData.rate) newErrors.rate = "Rate is required.";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      const errorMessage = Object.values(newErrors).join("\n");

      // Display the error message in SweetAlert2
      Swal.fire({
        title: "Validation Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });

      return false;
    }

    return true;
  };


  const getfactory = async () => {
    try {
      const response = await api.get(`factory-location/get-factory-location`);
      const data = response.data;
      const newData = data.map((item) => {
        return { value: item._id, label: item.fectory_loca_name }
      })
      setFactory(newData)
    } catch (error) {
      console.log(error)
    }
  }


  const generateUniqueId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  };

  const getItem = async () => {
    try {
      const response = await api.get('purchase-item/get-item');
      // console.log(response.data)
      const data = response.data;
      const newData = data.map((item) => {
        return {
          value: `${item._id}|${item.item_rate}`,
          label: item.item_title
        }
      })
      setItems(newData)
    } catch (error) {
      console.log(error)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Convert to number if necessary for calculations
    const rate = name === "rate" ? Number(value) : formData.rate || 0;
    const quantity = name === "quantity" ? Number(value) : formData.quantity || 0;

    // Calculate the new amount
    const total = rate * quantity;

    // Update the state with the new value and calculated amount
    setFormData({
      ...formData,
      [name]: value,
      amount: total,
    });
  };


  const handleCheckboxChange = () => {
    setFormData({ ...formData, addShift: !formData.addShift });
  };

  const handleSelectChange = (selectedOption, { name }) => {
    if (name == "item") {
      setFormData({
        ...formData, [name]: selectedOption.value.split("|")[0],
        item_name: selectedOption.label,
        rate: selectedOption.value.split("|")[1]
      });
    } else if (name == "godown") {
      setFormData({ ...formData, [name]: selectedOption.value, godown_name: selectedOption.label })
    } else {
      setFormData({ ...formData, [name]: selectedOption.value })
    }
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    const uniqueId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    const dataWithId = { ...formData, id: uniqueId };
    setFinalData((prevData) => [...prevData, dataWithId]);
    setFormData({
      ...formData,
      quantity: "",
      rate: "",
      amount: "",
    });
  }

  useEffect(() => {
    handleTotal();
  }, [finalData])
  // console.log(finalData)

  const handleTotal = () => {
    const { totalQuantity, totalAmount } = finalData.reduce(
      (totals, data) => {
        totals.totalQuantity += Number(data.quantity);
        totals.totalAmount += Number(data.amount); // Ensure this is numeric
        return totals;
      },
      { totalQuantity: 0, totalAmount: 0 } // Correct initial values
    );

    setTotalData({ totalQuantity, totalAmount });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setFinalData(finalData.filter((data) => data.id !== id));
        Swal.fire("Deleted!", "Your record has been deleted.", "success");
      }
    });
  };

  const handlefinalSubmit = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to submit this consumption data?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, submit it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const submitionData = finalData.map((item) => ({
          itemName: item.item,
          godown: item.godown,
          quantity: item.quantity,
          rate: item.rate,
          amount: item.amount,
        }));

        try {
          const response = await api.post("consumption/add-consumptions?type=Consumption", {
            addDate: formData.date,
            voucherNo: formData.voucherNo,
            consumeItems: submitionData,
            narration: formData.narration,
            totalAmount: totalData.totalAmount
          });
          Swal.fire({
            title: "Success!",
            text: "Consumption data submitted successfully.",
            icon: "success",
            timer: 1500, // Timer in milliseconds
            timerProgressBar: true, // Show a progress bar for the timer
          }).then(() => {
            // Optional: Perform an action after the timer ends, e.g., redirecting or resetting the form
            // console.log("Alert closed after timer.");
          });
          setFinalData([]);
        } catch (error) {
          console.log(error);
          Swal.fire("Error!", "Failed to submit data. Please try again.", "error");
        }
      }
    });

  }

  useEffect(() => {
    getfactory();
    getItem();
  }, [])

  return (
    <div className="w-[95%] mx-auto ">
      <div className="h-10 px-2 mt-5 flex justify-between items-center text-violet-500 font-bold rounded-md bg-[#fdfdfd] shadow mb-2">
        <div className="text-lg">SOURCE(CONSUMPTION)</div>
        <BackBtn />
      </div>
      <div className="p-6 mt-4 w-full mx-auto bg-white shadow-md rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium mb-1">Date</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Voucher No.</label>
            <input
              type="text"
              name="voucherNo"
              value={formData.voucherNo}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 gap-4 items-end mb-4">
          <div>
            <label className="block text-sm font-medium mb-1">Godown</label>
            <Select
              options={facatory}
              name="godown"
              onChange={handleSelectChange}
              className="w-full"
              placeholder="Select Godown"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Item</label>
            <Select
              options={item}
              name="item"
              onChange={handleSelectChange}
              className="w-full"
              placeholder="Select Item"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Quantity</label>
            <input
              type="number"
              name="quantity"
              value={formData.quantity}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Rate</label>
            <input
              type="text"
              value={formData.rate}
              // readOnly={formData.rate && formData.rate !== 0}
              name="rate"
              onChange={handleInputChange}
              className="w-full border border-gray-300 bg-gray-100 rounded px-3 py-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Amount</label>
            <input
              type="text"
              name="amount"
              value={formData.amount}
              readOnly
              className="w-full border border-gray-300 bg-gray-100 rounded px-3 py-2"
            />
          </div>
        </div>

        <button className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600"
          onClick={handleSubmit}
        >
          Add
        </button>

        <table className="w-full mt-4 border border-gray-300 text-left">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-2 border border-gray-300">Item Name</th>
              <th className="p-2 border border-gray-300">Godown</th>
              <th className="p-2 border border-gray-300">Quantity</th>
              <th className="p-2 border border-gray-300">Rate</th>
              <th className="p-2 border border-gray-300">Amount</th>
              <th className="p-2 border border-gray-300">Button</th>
            </tr>
          </thead>
          <tbody>
            {
              finalData && finalData.map((data, index) => (
                <tr key={index}>
                  <th className="p-2 border font-light">{data.item_name}</th>
                  <th className="p-2 border font-light">{data.godown_name}</th>
                  <th className="p-2 border font-light">{data.quantity}</th>
                  <th className="p-2 border font-light">{data.rate}</th>
                  <th className="p-2 border font-light">{data.amount}</th>
                  <th className="p-2 border  text-center">
                    <div className="p-1 border flex justify-center w-[30%]  hover:w-[35%] mx-auto rounded-lg bg-red-500 hover:bg-red-600 transition-all cursor-pointer"
                      onClick={() => handleDelete(data.id)}
                    >
                      <Trash2 className="" color="white" />
                    </div>
                  </th>
                </tr>
              ))
            }
          </tbody>
          <tbody>
            <tr>
              <td className="p-2  border-gray-300" colSpan="2">
                Total
              </td>
              <td className="p-2  border-gray-300">
                {totalData?.totalQuantity}
              </td>
              <td className="p-2  border-gray-300">

              </td>
              <td className="p-2  border-gray-300 " >
                {totalData?.totalAmount}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="mt-4">
          <label className="block text-sm font-medium mb-1">Narration</label>
          <textarea
            name="narration"
            value={formData.narration}
            onChange={handleInputChange}
            className="w-full border border-gray-300 rounded px-3 py-2"
          ></textarea>
        </div>

        <button className="mt-4 px-6 py-2 bg-teal-500 text-white rounded hover:bg-teal-600"
          onClick={() => handlefinalSubmit()}
        >
          Submit
        </button>
      </div>
    </div>

  );
};

export default ConsumptionForm;
