import React, { useEffect, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import api from './Axios/api';
import { Eye } from 'lucide-react';

const StockIssueReport = () => {
    const navigate = useNavigate();
    const [itemCategory, setItemCategory] = useState([]);

    const getCategories = async () => {
        try {
            const response = await api.get('/purchase-item-category/get-name');
            setItemCategory(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleAction = (row) => {
        navigate(`/stock-issue/${row.original._id}`);
    };

    useEffect(() => {
        getCategories();
    }, []);

    const columns = [
        {
            accessorKey: '_id',
            header: 'S.No',
            Cell: ({ row }) => row.index + 1,
        },
        {
            accessorKey: 'itemcat_title',
            header: 'Item Category',
        },
        {
            accessorKey: 'action',
            header: 'Action',
            Cell: ({ row }) => (
                <button
                    className="text-blue-500 hover:text-blue-700"
                    title="View"
                    onClick={() => handleAction(row)}
                >
                    <Eye size={18} />
                </button>
            ),
        },
    ];

    return (
        <div className="p-6">
            <div className='flex flex-row items-center justify-between bg-white p-3 mb-3 rounded-lg'>
                <h1 className="text-2xl font-bold text-gray-800">Stock Issue</h1>
                <button
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                    onClick={() => navigate('/stock-issue-form')}
                >
                    Item Issue
                </button>
            </div>

            <MaterialReactTable
                columns={columns}
                data={itemCategory}
                enablePagination
                enableBottomToolbar
                muiTableBodyRowProps={{ hover: true }}
            />
        </div>
    );
};

export default StockIssueReport;
