import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Eye } from 'lucide-react';

const QuotationComparison = ({ data }) => {
    console.log("data=>",data)
    const columns = useMemo(
        () => [
            {
                accessorKey: 'indent_date',
                header: 'Date',
                size: '15%',
                Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
            },
            {
                accessorKey: 'indent_number',
                header: 'Indent No.',
                size: '10%',
            },
            {
                accessorKey: 'branch_name',
                header: 'Factory Name',
                size: '15%',
            },
            {
                accessorKey: 'indentItems',
                header: 'Item Titles',
                size: '30%',
                Cell: ({ cell }) => (
                    <div className="max-h-10 overflow-y-auto">
                        {cell.getValue().map(item => (
                            <div key={item._id}>{item.item_title}</div>
                        ))}
                    </div>
                ),
            },
            {
                accessorKey: 'indent_remark',
                header: 'Remark',
                size: '10%',
            },
            {
                accessorKey: 'indent_by_name',
                header: 'Indent by name',
                size: '15%',
            },
            {
                accessorKey: '_id',
                header: 'Action',
                size: '5%',
                Cell: ({ cell, row }) => (
                    <Link
                        to={`/quote-comparison-detail/${row.original.unique_group_id}`}
                        className="m-2"
                    >
                        <button><Eye /></button>
                    </Link>
                ),
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data,
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
    });

    return <MaterialReactTable table={table} />;
}

export default QuotationComparison;