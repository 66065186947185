import React, { useCallback, useEffect, useState } from 'react';
import { CircleMinus, CirclePlus } from "lucide-react";
import Select from "react-select";
import api from '../Axios/api';

function ReceiptFormItems({
    items,
    index,
    receiptItems,
    setReceiptItems,
    addItem,
    deleteItem,
    isCase,
    checkedItems,
    itemGroupDropDown,
    purchaseItemDropdown,
    setPurchaseItemDropdown
}) {
    const [itemGroup, setItemGroup] = useState(null);

    // Fetch Purchase Item List
    const getPurchaseItemList = useCallback(async (id) => {
        try {
            const res = await api.get(`/purchase-item/get-item-name/${id || ""}`);
            const newData = res.data.map(item => ({
                value: item._id, label: item.item_title
            }));
            setPurchaseItemDropdown(newData);
        } catch (error) {
            console.error(error.response?.data || error);
        }
    }, []);

    // Handle Input Changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setReceiptItems(prevItems =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    [name]: value,
                } : item
            )
        );
        if (e.target.type === 'number') handleCalculations(e.target);
    };

    const handleCalculations = (element) => {
        const { name, value } = element;
        const item = receiptItems[index];
        let qty = Number(item.receipt_item_qty);
        let rate = Number(item.receipt_item_rate);
        let remainQty = Number(item.receipt_item_rem_qty);
        let originalQty = qty + remainQty;

        if (!isCase && name === 'receipt_item_qty' && value > originalQty) {
            setReceiptItems(prevItems =>
                prevItems.map((item, i) =>
                    i === index ? {
                        ...item,
                        receipt_item_qty: qty,
                    } : item
                )
            );
            return; // Early return if quantity exceeds original
        }

        if (name === 'receipt_item_qty') {
            qty = value;
            remainQty = originalQty - value;
        } else if (name === 'receipt_item_rate') {
            rate = value;
        }

        const [without_gst, with_gst] = totalAmountFormula(qty, rate, Number(item.receipt_item_disc), Number(item.receipt_item_gst));

        setReceiptItems(prevItems =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    receipt_item_rem_qty: remainQty,
                    receipt_item_total_without_gst: without_gst,
                    receipt_item_total_with_gst: with_gst,
                } : item
            )
        );
    }

    // Formula for Calculating Total Amount
    const totalAmountFormula = useCallback((qty, rate, discount, gst) => {
        const discPrice = rate - (rate * discount / 100);
        const extraGstPrice = (discPrice * qty) * (gst / 100);
        return [(qty * discPrice).toFixed(2), ((qty * discPrice) + extraGstPrice).toFixed(2)];
    }, []);

    const handleGroupChange = (e) => {
        setItemGroup(e);
        getPurchaseItemList(e.value);
    }

    // Set Original Quantity on Mount and Case Change
    // useEffect(() => {
    //     setOriginalQty(Number(receiptItems[index]?.receipt_item_qty) + Number(receiptItems[index]?.receipt_item_rem_qty));
    // }, [isCase]);

    return (
        <div key={index} className={`grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:${isCase ? 'grid-cols-6' : 'grid-cols-8  '} gap-6 border-t-2 p-4 border-red-300 w-full`}>
            {isCase && (
                <div>
                    <label className="mb-2 font-semibold text-gray-700">Item Group</label>
                    <Select
                        options={itemGroupDropDown}
                        value={itemGroupDropDown.find(option => option.value === itemGroup) || null}
                        onChange={handleGroupChange}
                        placeholder="Select Item Group..."
                        styles={{ menuList: () => ({ maxHeight: "200px", overflowY: "scroll" }) }}
                    />
                </div>
            )}

            <div>
                <label className="mb-2 font-semibold text-gray-700">Item Name</label>
                <Select
                    options={purchaseItemDropdown}
                    isDisabled={!isCase}
                    value={purchaseItemDropdown.find(option => option.value === items.receipt_item_id) || null}
                    onChange={e => handleChange({ target: { name: 'receipt_item_id', value: e.value } })}
                    placeholder="Select Item..."
                    styles={{
                        control: (base) => ({ ...base, backgroundColor: !isCase && '#E2E8F0' }),
                        singleValue: (base, state) => ({
                            ...base,
                            color: state.isDisabled ? 'black' : '#1A202C',
                        }),
                    }}
                />
            </div>

            <div className="">
                <label className="mb-2 font-semibold text-gray-700">
                    Items Qty <span className="text-xs text-red-500">(Total qty: {Number(items.receipt_item_qty) + Number(items.receipt_item_rem_qty)})</span>
                </label>
                <input
                    type="number"
                    disabled={!isCase && !checkedItems.includes(index)}
                    name="receipt_item_qty"
                    value={items.receipt_item_qty}
                    onChange={handleChange}
                    className={`block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${!isCase && !checkedItems.includes(index) && 'bg-slate-200'}`}
                    placeholder="Quantity"
                />
            </div>

            {!isCase && (
                <div>
                    <label className="mb-2 font-semibold text-gray-700">Items Rem. Qty</label>
                    <input
                        disabled={true}
                        type="number"
                        name="receipt_item_rem_qty"
                        value={items.receipt_item_rem_qty}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-slate-200"
                        placeholder="Remaining Quantity"
                    />
                </div>
            )}

            <div className="">
                <label className="mb-2 font-semibold text-gray-700">Items Rate</label>
                <input
                    type="number"
                    disabled={!isCase}
                    name="receipt_item_rate"
                    value={items.receipt_item_rate}
                    onChange={handleChange}
                    className={`block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${!isCase && `bg-slate-200`}`}
                    placeholder="Rate"
                />
            </div>



            {!isCase &&
                <div>
                    <label className="mb-2 font-semibold text-gray-700">Amount Without GST</label>
                    <input
                        disabled={true}
                        type="number"
                        name="receipt_item_amt"
                        value={items.receipt_item_total_without_gst}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6 bg-slate-200"
                        placeholder="Amount"
                    />
                    <span className="text-xs text-red-600">(Disc: {items.receipt_item_disc || 0}%)</span>
                </div>
            }

            <div>
                <label className="mb-2 font-semibold text-gray-700">Amount</label>
                <input
                    disabled={true}
                    type="number"
                    name="receipt_item_amt"
                    value={items.receipt_item_total_with_gst}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6 bg-slate-200"
                    placeholder="Amount"
                />
                <span className="text-xs text-red-600">(Disc: {items.receipt_item_disc || 0}%), (GST: {items.receipt_item_gst || 0}%)</span>
            </div>



            {!isCase && (
                <div>
                    <label className="mb-2 font-semibold text-gray-700">Remark</label>
                    <textarea
                        type="text"
                        disabled={!isCase && !checkedItems.includes(index)}
                        name="receipt_item_remark"
                        value={items.receipt_item_remark}
                        onChange={handleChange}
                        className={`block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${!isCase && !checkedItems.includes(index) && 'bg-slate-200'}`}
                        placeholder="Remark"
                    ></textarea>
                </div>
            )}
            {isCase &&
                <div className="flex receiptItems-end">
                    <CirclePlus onClick={addItem} color="purple" size={30} className="my-6  mx-1" />
                    <CircleMinus onClick={() => deleteItem(index)} size={30} color={"red"} className="my-6" />
                </div>}
        </div>
    )
}

export default ReceiptFormItems