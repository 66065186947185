import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from "../Axios/api";

const MaterialInOutView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data, status } = location.state;
    const { id } = useParams();
    console.log(data);

    const [formData, setFormData] = useState({
        workDone: data.workDone || "",
        warrantyGuarantee: data.warrantyGuarantee || "",
        checkedByMachine: data.checkedByMachine || "",
        expenseAmount: data.expenseAmount || "",
        repairedDate: new Date(data.repairedDate).toISOString().split('T')[0] || ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.put(`/material-in-out/update/${id}`, formData);
            alert("Data updated successfully!");
            navigate(-1);
        } catch (error) {
            console.error("Error updating data:", error);
            alert("Failed to update data.");
        }
    };

    return (
        <div className="p-4">
            <div className="flex justify-between items-center py-3 px-5 bg-white shadow-lg rounded-lg my-5">
                <h1 className="text-2xl font-bold text-gray-800">Material In/Out View</h1>
                <button
                    className="bg-purple-500 text-white py-2 px-6 rounded-lg hover:bg-purple-600 focus:outline-none"
                    onClick={() => {
                        navigate('/material-in-out');
                    }}
                >
                    Back
                </button>
            </div>

            {/* Immutable Data Section */}
            <div className="border p-4 rounded shadow-md bg-white">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block font-bold">Entry Date *</label>
                        <input
                            type="text"
                            value={data.date}
                            readOnly
                            className="w-full p-2 border rounded bg-gray-100 cursor-not-allowed"
                        />
                    </div>
                    <div>
                        <label className="block font-bold">Material Name *</label>
                        <input
                            type="text"
                            value={data.name}
                            readOnly
                            className="w-full p-2 border rounded bg-gray-100 cursor-not-allowed"
                        />
                    </div>
                    <div>
                        <label className="block font-bold">Model Number *</label>
                        <input
                            type="text"
                            value={data.modelNumber}
                            readOnly
                            className="w-full p-2 border rounded bg-gray-100 cursor-not-allowed"
                        />
                    </div>
                    <div>
                        <label className="block font-bold">Service Location *</label>
                        <input
                            type="text"
                            value={data.location}
                            readOnly
                            className="w-full p-2 border rounded bg-gray-100 cursor-not-allowed"
                        />
                    </div>
                    <div>
                        <label className="block font-bold">Explain Problem *</label>
                        <textarea
                            value={data.explainProblem || ""}
                            readOnly
                            className="w-full p-2 border rounded bg-gray-100 cursor-not-allowed"
                        ></textarea>
                    </div>
                    <div>
                        <label className="block font-bold">Expected Repaired Date *</label>
                        <input
                            type="text"
                            value={data.expectedRepairedDate || ""}
                            readOnly
                            className="w-full p-2 border rounded bg-gray-100 cursor-not-allowed"
                        />
                    </div>
                </div>
            </div>

            {/* Conditional Editable Section */}
            {status === "Pending" ? (
                <form className="mt-6 border p-4 rounded shadow-md bg-white" onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block font-bold">Work Done *</label>
                            <textarea
                                name="workDone"
                                value={formData.workDone}
                                onChange={handleChange}
                                className="w-full p-2 border rounded bg-gray-100"
                                required
                            ></textarea>
                        </div>
                        <div>
                            <label className="block font-bold">Warranty/Guarantee *</label>
                            <input
                                type="text"
                                name="warrantyGuarantee"
                                value={formData.warrantyGuarantee}
                                onChange={handleChange}
                                className="w-full p-2 border rounded bg-gray-100"
                                required
                            />
                        </div>
                        <div>
                            <label className="block font-bold">Checked by Machine *</label>
                            <input
                                type="text"
                                name="checkedByMachine"
                                value={formData.checkedByMachine}
                                onChange={handleChange}
                                className="w-full p-2 border rounded bg-gray-100"
                                required
                            />
                        </div>
                        <div>
                            <label className="block font-bold">Expense Amount *</label>
                            <input
                                type="number"
                                name="expenseAmount"
                                value={formData.expenseAmount}
                                onChange={handleChange}
                                className="w-full p-2 border rounded bg-gray-100"
                                required
                            />
                        </div>
                        <div>
                            <label className="block font-bold">Repaired Date *</label>
                            <input
                                type="date"
                                name="repairedDate"
                                value={formData.repairedDate}
                                onChange={handleChange}
                                className="w-full p-2 border rounded bg-gray-100"
                                required
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                    >
                        Submit
                    </button>
                </form>
            ) : (
                <div className="mt-6 border p-4 rounded shadow-md bg-white">
                    <h2 className="text-xl font-bold mb-4">Repair Information</h2>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block font-bold">Work Done</label>
                            <textarea
                                value={formData.workDone || "N/A"}
                                readOnly
                                className="w-full p-2 border rounded bg-gray-100 cursor-not-allowed"
                            ></textarea>
                        </div>
                        <div>
                            <label className="block font-bold">Warranty/Guarantee</label>
                            <input
                                type="text"
                                value={formData.warrantyGuarantee || "N/A"}
                                readOnly
                                className="w-full p-2 border rounded bg-gray-100 cursor-not-allowed"
                            />
                        </div>
                        <div>
                            <label className="block font-bold">Checked by Machine</label>
                            <input
                                type="text"
                                value={formData.checkedByMachine || "N/A"}
                                readOnly
                                className="w-full p-2 border rounded bg-gray-100 cursor-not-allowed"
                            />
                        </div>
                        <div>
                            <label className="block font-bold">Expense Amount</label>
                            <input
                                type="text"
                                value={formData.expenseAmount || "N/A"}
                                readOnly
                                className="w-full p-2 border rounded bg-gray-100 cursor-not-allowed"
                            />
                        </div>
                        <div>
                            <label className="block font-bold">Repaired Date</label>
                            <input
                                type="text"
                                value={formData.repairedDate || "N/A"}
                                readOnly
                                className="w-full p-2 border rounded bg-gray-100 cursor-not-allowed"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MaterialInOutView;
