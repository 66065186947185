import React from 'react';

function ReceiptFormOtherDetail({ formState, setFormState }) {
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 border-b-4 py-4">
            <div className="flex flex-col border-r-4 px-4 ">
                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Receipt Doc No.
                    </label>
                    <input
                        type="text"
                        name="receipt_doc_no"
                        value={formState.receipt_doc_no}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, receipt_doc_no: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Receipt Doc No."
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Dispatched Through
                    </label>
                    <input
                        type="text"
                        name="dispatched_through"
                        value={formState.dispatched_through}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, dispatched_through: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Dispatched Through"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Destination
                    </label>
                    <input
                        type="text"
                        name="destination"
                        value={formState.destination}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, destination: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Destination"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Carrier Name
                    </label>
                    <input
                        type="text"
                        name="carrier_name"
                        value={formState.carrier_name}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, carrier_name: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Carrier Name"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Bill Of Lading
                    </label>
                    <input
                        type="text"
                        name="bill_of_lading"
                        value={formState.bill_of_lading}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, bill_of_lading: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder=" Bill Of Lading"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Motor Vehicle No.
                    </label>
                    <input
                        type="text"
                        name="motor_vehicle_no"
                        value={formState.motor_vehicle_no}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, motor_vehicle_no: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Motor Vehicle No."
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Insurance Policy No.
                    </label>
                    <input
                        type="text"
                        name="insurance_policy_no"
                        value={formState.insurance_policy_no}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, insurance_policy_no: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Insurance Policy No."
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Contact Person
                    </label>
                    <input
                        type="text"
                        name="contact_person"
                        value={formState.contact_person}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, contact_person: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Contact Person"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Vehicle No.
                    </label>
                    <input
                        type="text"
                        name="vehicle_no"
                        value={formState.vehicle_no}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, vehicle_no: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Vehicle No."
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Driver Mobile No.
                    </label>
                    <input
                        type="text"
                        max={10}
                        name="driver_mobile_no"
                        value={formState.driver_mobile_no}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, driver_mobile_no: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Driver Mobile No"
                    />
                </div>
            </div>

            {/* Other Detail First Section */}
            <div className="flex flex-col">
                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Challan No <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="challan_no"
                        value={formState.challan_no}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, challan_no: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Challan No"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Weight Slip No
                    </label>
                    <input
                        type="text"
                        name="weight_slip_no"
                        value={formState.weight_slip_no}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, weight_slip_no: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Weight Slip No"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Fright Advice No
                    </label>
                    <input
                        type="text"
                        name="fright_advice_no"
                        value={formState.fright_advice_no}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, fright_advice_no: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Fright Advice No."
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Stores Remarks <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="stores_remarks"
                        value={formState.stores_remarks}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, stores_remarks: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Stores Remarks"
                    />
                </div>
            </div>

            {/* Other Detail Second Section */}
            <div className="flex flex-col">
                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Challan Date <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="date"
                        name="challan_date"
                        value={formState.challan_date}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, challan_date: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Challan Date"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Weight Slip Date
                    </label>
                    <input
                        type="date"
                        name="weight_slip_date"
                        value={formState.weight_slip_date}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, weight_slip_date: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Weight Slip Date"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Gate Entry No <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="gate_entry_no"
                        value={formState.gate_entry_no}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, gate_entry_no: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Gate Entry No"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-2 font-semibold text-gray-700">
                        Fright Rs
                    </label>
                    <input
                        type="text"
                        name="fright_rs"
                        value={formState.fright_rs}
                        onChange={(e) => { setFormState((prev) => ({ ...prev, fright_rs: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                        placeholder="Fright Rs"
                    />
                </div>
            </div>
        </div>
    )
}

export default ReceiptFormOtherDetail