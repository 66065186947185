import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Checkbox } from '@mui/material';
import api from './Axios/api';
import { Eye, Pencil } from 'lucide-react';



const ReturnList = () => {


    const [data, setData] = useState([]);
    const navigation = useNavigate();


    const getData = async () => {
        try {
            const res = await api.get(`/purchase/return-indent-item-list`)
            setData(res.data)
            // console.log(res.data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getData()
    }, [])


    const columns = useMemo(
        () => [
            {
                accessorKey: 'indent_date',
                header: 'Indent Date',
                size: 150,
                Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
            },
            {
                accessorKey: 'indent_number',
                header: 'Indent Number',
                size: 150,
            },
            {
                accessorKey: 'indent_branch_name',
                header: 'Branch Name',
                size: 200,
                Cell: ({ cell }) => (
                    <div className="max-h-10 overflow-y-auto">
                        {cell.getValue().split(' ')[1]}
                    </div>
                ),
            },
            {
                accessorKey: 'items',
                header: 'Item Titles',
                size: 300,
                Cell: ({ cell }) => (
                    <div className="max-h-10 overflow-y-auto">
                        {cell.getValue().map(item => (
                            <div key={item._id}>{item.item_title}</div>
                        ))}
                    </div>
                ),
            },
            {
                accessorKey: 'indent_remark',
                header: 'Remark',
                size: 200,
            },
            {
                accessorKey: 'indent_by_name',
                header: 'Indent By',
                size: 150,
            },
            {
                accessorKey: '_id',
                header: 'Action',
                size: 200,
                Cell: ({ cell }) => (
                    <Link to={`/details/${cell.getValue()}`} state={{ return: "true" }}>
                        <button><Eye size={20} /></button>
                    </Link>
                ),
            },
            {
                accessorKey: 'indent_status',
                header: 'Status',
                size: 150,
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data,
        // enableRowSelection: true,
        // renderToolbarAlertBannerContent: (tableInstance) => {
        //     const selectedRowIds = Object.keys(tableInstance.table.getState().rowSelection);
        //     const selectedRows = selectedRowIds.map(id => tableInstance.table.getRow(id).original);
        //     const ids = selectedRows.map((item) => item._id)
        //     return (
        //         <div className='flex items-center gap-3'>
        //             {tableInstance.selectedAlert}
        //             <div className='text-sm text-[#36C2CE] cursor-pointer'
        //                 onClick={(e) => { navigation(`/create-po-form`, { state: { ids } }) }}
        //             >
        //                 Create PO
        //             </div>
        //         </div>
        //     )
        // }
    });

    return (
        <div className="flex-1 px-5 w-full justify-center items-center">
            <div className="h-10 px-2 flex justify-between items-center text-violet-500 font-bold rounded-md bg-[#fdfdfd] shadow my-2">
                <div className="text-lg">Return items</div>
            </div>
            <div className="w-full min-h-[500px] rounded-md shadow bg-[#fdfdfd]">
                <MaterialReactTable table={table} />
            </div>
        </div>
    );
};

export default ReturnList;
