import React, { useMemo } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { Link, useNavigate } from 'react-router-dom';
import { Pencil } from 'lucide-react';

const IndentDetailTable = ({ data, type }) => {

    const navigation = useNavigate();

    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'added_on',
            //     header: 'Date',
            //     size: 150,
            //     enableEditing: false,
            //     Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
            // },
            {
                accessorKey: 'item_group',
                header: 'Item Group',
                size: 200,
            },
            {
                accessorKey: 'item_title',
                header: 'Item',
                size: 150,
                Cell: ({ cell }) => (
                    <div className="max-h-10 overflow-y-auto">
                        {cell.getValue()}
                    </div>
                ),
            },
            {
                accessorKey: 'place_of_use',
                header: 'Remark',
                size: 200,
            },
            {
                accessorKey: 'required_qty',
                header: 'Required qty',
                size: 200,
            },
            {
                accessorKey: 'remark',
                header: 'Place of use',
                size: 200,
            },
            {
                accessorKey: 'current_stock',
                header: 'Current Stock',
                size: 200,
            },
            // {
            //     accessorKey: '_id',
            //     header: 'Action',
            //     size: 200,
            //     Cell: ({ cell }) => (
            //         <div className='flex items-center gap-3'>

            //             <Link to={`/edit/${cell.getValue()}`}>
            //                 <button><Pencil size={18} /></button>
            //             </Link>
            //         </div>
            //     ),
            // },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowActions: type,
        renderRowActions: ({ row, table }) => (
            <Link to={`/return-purchase-edit/${row.original._id}`}>
                <button><Pencil size={18} /></button>
            </Link>
        ),
        enableRowSelection: type ? false : true,
        renderToolbarAlertBannerContent: (tableInstance) => {
            const selectedRowIds = Object.keys(tableInstance.table.getState().rowSelection);
            const selectedRows = selectedRowIds.map(id => tableInstance.table.getRow(id).original);
            const ids = selectedRows.map((item) => item._id)
            return (
                <div className='flex items-center gap-3'>
                    {tableInstance.selectedAlert}
                    <div className='text-sm text-[#36C2CE] cursor-pointer'
                        onClick={(e) => { navigation(`/add-purchase-details`, { state: { ids } }) }}
                    >
                        Add Rate
                    </div>
                </div>
            )
        }
    });

    return (
        <div>
            <MaterialReactTable table={table} />
        </div>
    );
};

export default IndentDetailTable;
