import React, { useEffect, useState } from "react";
import Sidebar, { SidebarItem } from "./Sidebar";
import { useSelector } from "react-redux";
import api from "../Axios/api";
import {
  LifeBuoy,
  ReceiptText,
  ShoppingBasket,
  ShoppingBag,
  GitCompare,
  PackageCheck,
  SquareKanban,
  LayoutDashboard,
  Settings,
  FilePenLine,
  IndianRupee,
  Construction,
  Undo2,
  Book,
  Minus,
  AlignVerticalJustifyEnd,
  FolderPlus,
  Handshake,
  Blocks

} from "lucide-react";

const SideNav = () => {
  const userId = useSelector((state) => state.user._id);
  const UserName = useSelector((state) => state.user.staff_name);
  const [access, setAccess] = useState([]);


  const fetchAccess = async () => {
    try {
      const response = await api.get("staff/get");
      const currentUser = response.data.filter((res) => res._id === userId);
      console.log("access data", currentUser);
      setAccess(currentUser[0].subaccessnames);
    } catch (error) {
      console.error("Failed to fetch access data:", error);
    }
  };
  console.log("data Access", access)



  useEffect(() => {
    const el = document.querySelector(".navList").children;
    fetchAccess();
    for (const iterator of el) {
      iterator.addEventListener("click", function () {
        for (const element of el) {
          element.classList.remove("bg-gradient-to-tr");
          element.classList.remove("from-indigo-200");
          element.classList.remove("to-indigo-100");
          element.classList.remove("text-indigo-800");
        }
        this.classList.add("bg-gradient-to-tr");
        this.classList.add("from-indigo-200");
        this.classList.add("to-indigo-100");
        this.classList.add("text-indigo-800");
      });
    }
  }, []);
  return (
    <Sidebar>

      {(access.length > 0 && access.some(item => item.submodulename === "Dashboard") || UserName === "Admin" )&& (
        <SidebarItem
          icon={<LayoutDashboard size={20} />}
          text="Dashboard"
          alert
          to="/dashboard"
        />
      )}


      {(access.length > 0 && access.some(item => item.submodulename === "Indent")|| UserName === "Admin") && (
        <SidebarItem icon={<SquareKanban size={20} />} text="Indent" to="/" />
      )}

      {(access.length > 0 && access.some(item => item.submodulename === "Return List")|| UserName === "Admin" )&& (
        <SidebarItem
          icon={<Undo2 size={20} />}
          text="Return List"
          to="/returnList"
        />
      )}

      {(access.length > 0 && access.some(item => item.submodulename === "Quotation")|| UserName === "Admin") && (
        <SidebarItem
          icon={<GitCompare size={20} />}
          text="Quotation Comparison"
          to="/quote-comparison"
        />
      )}


      {(access.length > 0 && access.some(item => item.submodulename === "Approval List")|| UserName === "Admin") && (
        <SidebarItem
          icon={<ShoppingBag size={20} />}
          text="Approval List"
          alert
          to="/approval-list"
        />
      )}


      {(access.length > 0 && access.some(item => item.submodulename === "Created PO")|| UserName === "Admin") && (
        <SidebarItem
          icon={<PackageCheck size={20} />}
          text="Created Po"
          to="/created-po-list"
        />
      )}

     {(access.length > 0 && access.some(item => item.submodulename === "Receipt Note")|| UserName === "Admin") && (
      <SidebarItem
        icon={<ReceiptText size={20} />}
        text="Receipt Note"
        to="/receipt-note-report"
      />
     )}
     
     {(access.length > 0 && access.some(item => item.submodulename === "Purchase Entry")|| UserName === "Admin") && (
      <SidebarItem
        icon={<FilePenLine size={20} />}
        text="Purchase Entry"
        to="/purchase-entry"
      />
     )}
      {(access.length > 0 && access.some(item => item.submodulename === "Payment")|| UserName === "Admin") && (
      <SidebarItem
        icon={<IndianRupee size={20} />}
        text="Payment"
        to="/purchase-payment"
      />
      )}
    

    
 
      {(access.length > 0 && access.some(item => item.submodulename === "Consumption")|| UserName === "Admin")&& (
        <SidebarItem
          icon={<FilePenLine size={20} />}
          text="Consumption Form"
          to="/consumption-form"
        />
      )}

      {(access.length > 0 && access.some(item => item.submodulename === "Production")|| UserName === "Admin")&& (
        <SidebarItem
          icon={<FilePenLine size={20} />}
          text="Production Form"
          to="/production-form"
        />
      )}

      {(access.length > 0 && access.some(item => item.submodulename === "Purchase Report")|| UserName === "Admin") && (
        <SidebarItem
          icon={<Book size={20} />}
          text="Report"
          to="/report"
        />
      )}

      {(access.length > 0 && access.some(item => item.submodulename === "Purchase Master") || UserName === "Admin")&& (
        <SidebarItem
          icon={<Minus size={20} />}
          text="Master"
        // to="/report"
        />
      )}

      {(access.length > 0 && access.some(item => item.submodulename === "Add Category")|| UserName === "Admin") && (
        <SidebarItem
          icon={<FolderPlus size={20} />}
          text="Add Category"
          to="/add-category"
        />
      )}

      {(access.length > 0 && access.some(item => item.submodulename === "Add Items") || UserName === "Admin")&& (
        <SidebarItem
          icon={<AlignVerticalJustifyEnd size={20} />}
          text="Add Items"
          to="/add-items"
        />
      )}
      {(access.length > 0 && access.some(item => item.submodulename === "Add Party")|| UserName === "Admin") && (
        <SidebarItem
          icon={<Handshake size={20} />}
          text="Add Party"
          to="/add-party"
        />
      )}

      {(access.length > 0 && access.some(item => item.submodulename === "Material In/Out")|| UserName === "Admin") && (
        <SidebarItem
          icon={<AlignVerticalJustifyEnd size={20} />}
          text="Material In Out"
          to="/material-in-out"
        />
      )}

      {(access.length > 0 && access.some(item => item.submodulename === "Stock Issue")|| UserName === "Admin") && (
        <SidebarItem
          icon={<Blocks size={20} />}
          text="Stock Issue"
          to="/stock-issue"
        />
      )}
    </Sidebar>
  );
};

export default SideNav;
