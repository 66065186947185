import React, { useEffect, useState } from 'react';
import HeadTag from './HeadTag';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import api from './Axios/api';
import Swal from 'sweetalert2';
import { Trash2 } from 'lucide-react';

const AddPurchaseDetails = () => {
    const [splitOption, setSplitOption] = useState([]);
    const [brandOption, setBrandOption] = useState([]);
    const [discountOption, setDiscountOption] = useState([]);
    const [quotation, setQuotation] = useState([]);
    const [items, setItems] = useState([]);
    const [show, setShow] = useState(false);
    const [parties, setParties] = useState([]);
    const [brands, setBrands] = useState([]);
    const location = useLocation();
    const { ids } = location.state || {};
    const [enable, setEnable] = useState(false);
    const navigate = useNavigate();

    const initialState = {
        item_id: "",
        partyName: '',
        itemName: '',
        rate: 0,
        brand: '',
        item_gst: "",
        required_qty: 0,
        discount: 0,
        splitQty: '',
        withGst: 0,
        withoutGst: 0,
    };

    const getItems = () => {
        api
            .get(`indent/get-indent-item/${ids.join(',')}`)
            .then((res) => {
                setItems(res.data);
                // console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getParty = async () => {
        try {
            const res = await api.get(`vendor/get-name`)
            const data = res.data;

            const newArr = data.map((party, index) => {
                let val = party._id + "_" + party.vendor_name;
                return { label: party.vendor_name, value: val }
            })
            setParties(newArr)
        } catch (error) {
            console.log(error.response.data)
        }
    }

    const deleteQuotation = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setQuotation((prev) => prev.filter((_, i) => i !== index));
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })
    };

    const getBrands = async () => {
        try {
            const res = await api.get(`brand/get`);
            const data = res.data;

            const newArr = data.map((brand, index) => {
                let val = brand._id + "_" + brand.brand_name;
                return { label: brand.brand_name, value: val }
            });
            setBrands(newArr);
        } catch (error) {
            console.log(error.response.data);
        }
    }

    const submit = async () => {
        try {
            const result = quotation.map((data) => ({
                "indent_item_id": data.item_id,
                "party": data.partyName.split("_")[0],
                "rate": data.rate,
                "brand": data?.brand ? data?.brand.split("_")[0] : null,
                "discount": data.discount,
                "gst": 18,
                "total_without_gst": data.withoutGst,
                "total_with_gst": data.withGst,
                "purchase_qty": data.splitQty || data.required_qty,
            }));
            // console.log(result);

            const res = await api.post(`purchase/add`, result);
            // console.log(res.data);

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Quotation submitted successfully!',
                html: `<button id="customButton" class="swal2-confirm swal2-styled">Ok</button>`,
                // timer: 1500,
                showConfirmButton: false,
                didOpen: () => {
                    const customButton = document.getElementById('customButton');
                    customButton.addEventListener('click', () => {
                        Swal.close();
                        navigate("/")
                    });
                }
            });

            setInputs([]);
            setQuotation([]);
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to submit quotation. Please try again.',
            });
        }
    };

    // inputs
    const [inputs, setInputs] = useState([]);
    useEffect(() => {
        const initialRows = items.map((item) => (
            {
                ...initialState,
                item_gst: item.item_gst,
                item_id: item._id,
                itemName: item.item_title,
                required_qty: item.required_qty,
                actual_qty: item.required_qty
            }
        ));
        setInputs(initialRows);
    }, [items]);

    const handleSplit = (index) => {
        if (!splitOption.includes(index)) {
            setSplitOption((prev) => [...prev, index]);
        } else {
            const data = splitOption.filter((item) => item !== index);
            setSplitOption(data);
        }
    };

    const handleBrand = (index) => {
        if (!brandOption.includes(index)) {
            setBrandOption((prev) => [...prev, index]);
        } else {
            const data = brandOption.filter((item) => item !== index);
            setBrandOption(data);
        }
    };

    const handleDiscount = (index) => {
        if (!discountOption.includes(index)) {
            setDiscountOption((prev) => [...prev, index]);
        } else {
            const data = discountOption.filter((item) => item !== index);
            setDiscountOption(data);
        }
    };

    const handleChange = (index, field, value) => {
        const newInputs = [...inputs];
        newInputs[index] = { ...newInputs[index], [field]: value };
        if (field == "rate" || field == "discount") {
            const val = withoutGstCalc(index, field, value);
            const val1 = withGstCalc(index, field, value);
            newInputs[index] = { ...newInputs[index], ["withoutGst"]: val, ["withGst"]: val1 };
        } else if (field == "splitQty") {
            newInputs[index] = { ...newInputs[index], ['splitQty']: value, ['actual_qty']: value };
        }


        setInputs(newInputs);
    };

    const validateForm = () => {

        for (let i = 0; i < inputs.length; i++) {
            const input = inputs[i];
            if (!input.partyName || !input.rate || (splitOption.includes(i) && !input.splitQty) || (discountOption.includes(i) && !input.discount) || (brandOption.includes(i) && !input.brand)) {
                return false;
            }
        }
        return true;
    };

    // handler SplitQty
    const handleSplitQty = (splitQty, index) => {
        // console.log(inputs[index]?.required_qty)
        if (splitQty <= inputs[index]?.required_qty) {
            handleChange(index, 'splitQty', splitQty)
        } else {
            handleChange(index, 'splitQty', inputs[index]?.splitQty)
        }
    }

    const addQuotation = () => {
        if (validateForm()) {


            const items = inputs;

            const newItem = items.map((item) => ({
                ...item,
                required_qty: item.actual_qty // Assuming you want to update `
            }))



            setQuotation((prev) => [...prev, ...newItem]);

            // Show success confirmation
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Quotation added successfully!',
                timer: 1500,
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fill all required fields',
                timer: 1500,
            });
        }
        setEnable(true);
    };

    const withoutGstCalc = (index, field, value) => {
        let discountedRate;
        if (field == "rate") {
            const totalItemRate = inputs[index]?.required_qty * value;
            const discount = totalItemRate * inputs[index]?.discount / 100;
            discountedRate = totalItemRate - discount;
        } else if (field == "discount") {
            const totalItemRate = inputs[index]?.required_qty * inputs[index]?.rate;
            const discount = totalItemRate * value / 100;
            discountedRate = totalItemRate - discount
        }
        return discountedRate;
    };

    const withGstCalc = (index, field, value) => {
        let price = withoutGstCalc(index, field, value);
        let gstAmount = Math.round((price * Number(inputs[index].item_gst)) / 100);
        // handleChange(index, 'withoutGst', Math.round(discountedPrice))
        return price + gstAmount;
    }

    const updatePartyForAll = (partyName) => {
        const updatedData = inputs.map(item => ({
            ...item,
            partyName: partyName
        }));
        setInputs(updatedData);
    };

    useEffect(() => {
        getItems();
        getParty();
        getBrands();
    }, []);

    return (
        <div className='w-full flex flex-col justify-center items-center relative'>
            <HeadTag
                className='relative'
                tagName='Purchase Comparison Detail'
                to='/indentForm'
                type='modal'
                buttonName={'Add Brand'}
                set={setShow}
                show={show}
            />

            <div
                className={`absolute z-20 transition-all duration-500 ${show
                    ? 'w-11/12 mx-auto p-3 rounded flex justify-between items-center bg-[#d8d8d8fd] mt-3'
                    : 'w-0'
                    } `}>
                <input
                    type='text'
                    placeholder='Add Brand'
                    className={`block transition-all duration-500 ${show
                        ? 'w-[50%] rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                        : 'w-0'
                        }`}
                />
                <div
                    className={`transition-all duration-500 ${show
                        ? 'w-[10%] bg-[#7E8EF1]'
                        : 'w-0'} text-center transition-colors rounded-md shadow-sm p-1 hover:bg-[#615EFC] hover:ease-in text-[#ffff] text-bold`}>
                    {show ? '+Add' : ''}
                </div>
            </div>

            {/*add purchase details */}
            <div className='w-11/12 mt-3 flex justify-center rounded : bg-[#fdfdfdfd]'>
                <div className='container mx-auto p-4'>
                    <div className='overflow-x-auto'>
                        <table className='min-w-full mb-28 bg-white border border-gray-200'>
                            <thead>
                                <tr>
                                    <th className='px-4 py-2 border'>Split Quantity</th>
                                    <th className='px-4 py-2 border'>Item</th>
                                    <th className='px-4 py-2 border'>Gst.</th>
                                    <th className='px-4 py-2 border'>Qty</th>
                                    <th className='px-4 py-2 border'>Select Party</th>
                                    <th className='px-4 py-2 border'>Rate</th>
                                    <th className='px-4 py-2 border'>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.length > 0 &&
                                    items?.map((item, index) => (
                                        <tr key={index}>
                                            <td className='px-4 py-2 border text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='form-checkbox'
                                                    onClick={() => handleSplit(index)}
                                                />
                                            </td>
                                            <td className='px-4 py-2 border text-center'>
                                                {item.item_title}
                                            </td>
                                            <td className='px-4 py-2 border'>
                                                <h4>{item.item_gst}%</h4>
                                            </td>
                                            <td className='px-4 py-2 border text-center'>{item.required_qty}{item.item_unit}</td>
                                            <td className='px-4 py-2 border'>
                                                <Select
                                                    options={parties}
                                                    defaultValue={inputs[index]?.partyName}
                                                    placeholder={inputs[index]?.partyName?.split("_")[1] || "Select party"}
                                                    onChange={(e) => {
                                                        // handleChange(index, 'partyName', e.value)
                                                        updatePartyForAll(e.value)
                                                    }}
                                                    styles={{
                                                        menuList: () => ({
                                                            overflowY: 'scroll',
                                                            maxHeight: '120px',

                                                        }),
                                                        input: (baseStyles) => ({
                                                            ...baseStyles,
                                                            width: '180px',

                                                        }),
                                                    }}
                                                />
                                            </td>
                                            <td className='px-4 py-2 border'>
                                                <div className='flex flex-col gap-2'>
                                                    <input
                                                        type='number'
                                                        placeholder='Enter Rate'
                                                        className='block w-full rounded outline-none border-1 py-1.5 pl-7 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6'
                                                        value={inputs[index]?.rate}
                                                        onChange={(e) =>
                                                            handleChange(index, 'rate', e.target.value)
                                                        }
                                                    />
                                                    {splitOption.includes(index) && (
                                                        <input
                                                            type='number'
                                                            placeholder={'Split Qty'}
                                                            className={`block w-full rounded outline-none border-1 py-1.5 pl-7 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 ${inputs[index]?.splitQty > inputs[index]?.required_qty ? "focus:ring-red-400" : "focus:ring-blue-400 focus:border-blue-400"}  sm:text-sm sm:leading-6`}
                                                            value={inputs[index]?.splitQty}
                                                            onChange={(e) => {
                                                                handleSplitQty(e.target.value, index)

                                                            }}
                                                            disabled={enable}
                                                        />
                                                    )}
                                                    {discountOption.includes(index) && (
                                                        <input
                                                            type='number'
                                                            placeholder='Discount'
                                                            className='block w-full rounded outline-none border-1 py-1.5 pl-7 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6'
                                                            value={inputs[index]?.discount}
                                                            onChange={(e) => handleChange(index, 'discount', e.target.value)}
                                                        />
                                                    )}
                                                </div>
                                                <div className='flex justify-evenly'>
                                                    <button
                                                        className='text-blue-500 mt-1 mx-1 sm:text-[75%] text-[50%]'
                                                        onClick={() => handleBrand(index)}>
                                                        Brand Name+
                                                    </button>
                                                    <button
                                                        className='text-blue-500 mt-1 sm:text-[75%] text-[50%]'
                                                        onClick={() => handleDiscount(index)}>
                                                        Discount +
                                                    </button>
                                                </div>
                                                {brandOption.includes(index) && (
                                                    <div>
                                                        <Select
                                                            options={brands}
                                                            placeholder={"Select Brand..."}
                                                            onChange={(e) => handleChange(index, 'brand', e.value)}
                                                            styles={{
                                                                menuList: () => ({
                                                                    overflowY: 'scroll',
                                                                    maxHeight: '120px',
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </td>
                                            <td className='px-2 py-2 border text-center'>
                                                <div className='flex flex-col justify-end gap-2 h-full w-full '>
                                                    <div className='text-sm text-bold'>
                                                        Total: (with Charge):&nbsp;<span className="font-bold ">₹{Math.round(inputs[index]?.withoutGst)}</span>
                                                    </div>
                                                    <div className='text-sm text-bold'>
                                                        Total: (GST+Charge):&nbsp;<span className="font-bold ">₹{Math.round(inputs[index]?.withGst)}</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                {/* <tr>
                                    <td className='px-4 py-2 border text-center'>Total</td>
                                    <td className='px-4 py-2' colSpan={5}>
                                        <div className='flex justify-end gap-3 h-full w-full '>
                                            <div className='text-sm text-bold'>
                                                Total (Including Charge & Without Gst): {withoutGstCalc()}
                                            </div>
                                            <div className='text-sm text-bold'>
                                                Total (Including GST & Charge): 0
                                            </div>
                                        </div>
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-4'>
                        <div className='flex justify-between items-center'>
                            <div className='flex-1'>
                                <div className='flex items-center'>
                                    <label className='mr-2'>Charge</label>
                                    <input type='checkbox' className='form-checkbox' checked />
                                    <label className='ml-2'>18% GST</label>
                                </div>
                            </div>

                            <div
                                className='w-[20%] sm:w-[10%] text-center rounded shadow cursor-pointer bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800 hover:bg-indigo-600'
                                onClick={addQuotation}>
                                +Add
                            </div>
                            {quotation.length > 0 && <div
                                className='w-[20%] sm:w-[10%] mx-3 text-center rounded shadow cursor-pointer bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800 hover:bg-indigo-600'
                                onClick={submit}>
                                submit
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            {/* show added details */}
            <div className='w-11/12 mt-3 flex flex-col justify-center items-center rounded bg-[#fdfdfdfd]'>
                <div className='font-bold text-lg'>Purchase Details</div>

                <div className='container mx-auto p-4'>
                    <div className='overflow-x-auto'>
                        <table className='min-w-full bg-white border border-gray-200'>
                            <thead>
                                <tr>
                                    <th className='px-4 py-2 border'>Item</th>
                                    <th className='px-4 py-2 border'>Qty</th>
                                    <th className='px-4 py-2 border'>brand</th>
                                    <th className='px-4 py-2 border'>Gst.</th>
                                    <th className='px-4 py-2 border'>Discount</th>
                                    <th className='px-4 py-2 border'>Select Party</th>
                                    <th className='px-4 py-2 border'>Rate</th>
                                    <th className='px-4 py-2 border'>Grand Total</th>
                                    <th className='px-4 py-2 border'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quotation.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className={`px-4 py-2 border text-center`}>
                                                {item.itemName}
                                            </td>
                                            <td className={`px-4 py-2 border text-center`}>
                                                {item.required_qty}
                                            </td>

                                            <td className={`px-4 py-2 border text-center`}>
                                                {item.brand.split('_')[1]}
                                            </td>
                                            <td className={`px-4 py-2 border text-center`}>
                                                {/* {item.withGst} */} 18%
                                            </td>

                                            <td className={`px-4 py-2 border text-center`}>
                                                {item.discount}
                                            </td>
                                            <td className={`px-4 py-2 border text-center`}>
                                                {item.partyName.split('_')[1]}
                                            </td>
                                            <td className={`px-4 py-2 border text-center`}>
                                                {item.rate}
                                            </td>
                                            <td className={`px-4 py-2 border text-center`}>
                                                Without GST -  {Math.round(item.withoutGst)} ,With GST - {Math.round(item.withGst)}
                                            </td>
                                            <td className={`px-4 py-2 border text-center`}>
                                                <div className='cursor-pointer flex justify-center' onClick={() => deleteQuotation(index)}>
                                                    <Trash2 className='bg-[#E4003A]' />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPurchaseDetails;