// ItemForm.js
import React, { useCallback, useState } from "react";
import api from "../Axios/api";

const PartyForm = ({
    inputs,
    setInputs,
    append,
    setAppend,
    editId,
    citylist,
    setCityList,
    addParty,
    editItem,
    resetHandler,
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newCity, setNewCity] = useState("");
    const [newState, setNewState] = useState("");

    const handleAddCity = async () => {
        if (!newCity.trim() || !newState.trim()) {
            alert("Both City Name and State are required!");
            return;
        }

        try {
            const response = await api.post("/vendor-city/add-city", {
                city_name: newCity,
                city_state: newState
            });

            if (response.status === 201) {
                const newCityData = response.data.city;
                console.log(newCityData)
                setCityList((prevCityList) => [...prevCityList, newCityData]);
                setIsModalOpen(false);
                setNewCity("");
                setNewState("");
            }
            else {
                alert("Failed to add city!");
            }
        } catch (error) {
            console.error("Error adding city:", error);
            alert("Something went wrong!");
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs((prev) => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (e, stateKey, appendKey) => {
        const { value, selectedOptions } = e.target;
        setInputs((prev) => ({ ...prev, [stateKey]: value }));
        if (appendKey && selectedOptions.length > 0) {
            setAppend((prev) => ({ ...prev, [appendKey]: selectedOptions[0].text }));
        }
    };

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            if (editId) {
                editItem(e, editId);
            } else {
                addParty(e);
            }
        },
        [editId, addParty, editItem]
    );

    return (
        <div className="bg-white p-4 rounded-lg shadow-md w-[33rem]">
            <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold mb-4">{editId ? "Edit Party" : "Party Form"}</h2>
                <button
                    type="button"
                    className="bg-purple-600 text-white font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-purple-700 transition-all"
                    onClick={() => setIsModalOpen(true)}
                >
                    Add City
                </button>
            </div>

            {isModalOpen && (
                <div className="bg-gray-100 p-4 rounded-lg shadow-md my-4">
                    <h3 className="text-lg font-semibold mb-2">Add City</h3>
                    <input
                        type="text"
                        placeholder="Enter City Name"
                        value={newCity}
                        onChange={(e) => setNewCity(e.target.value)}
                        className="w-full border rounded-md px-4 py-2 mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <input
                        type="text"
                        placeholder="Enter State"
                        value={newState}
                        onChange={(e) => setNewState(e.target.value)}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <div className="flex justify-end gap-2 mt-4">
                        <button
                            type="button"
                            onClick={() => setIsModalOpen(false)}
                            className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleAddCity}
                            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
                        >
                            Save City
                        </button>
                    </div>
                </div>
            )}

            <form className="space-y-2" onSubmit={handleSubmit}>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Party Name<span className="text-red-600">*</span>
                    </label>
                    <input
                        type="text"
                        name="vendor_name"
                        placeholder="Enter Party Name"
                        onChange={handleInputChange}
                        value={inputs.vendor_name}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Added On<span className="text-red-600">*</span>
                    </label>
                    <input
                        type="date"
                        name="vendor_added_on"
                        onChange={handleInputChange}
                        value={inputs.vendor_added_on}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Mobile No.<span className="text-red-600">*</span>
                    </label>
                    <input
                        type="number"
                        name="vendor_mobile"
                        placeholder="Enter Mobile No."
                        onChange={handleInputChange}
                        value={inputs.vendor_mobile}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Email<span className="text-red-600">*</span>
                    </label>
                    <input
                        type="email"
                        name="vendor_email"
                        placeholder="Enter Email"
                        onChange={handleInputChange}
                        value={inputs.vendor_email}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Address<span className="text-red-600">*</span>
                    </label>
                    <input
                        type="text"
                        name="vendor_address"
                        placeholder="Enter Address"
                        onChange={handleInputChange}
                        value={inputs.vendor_address}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Area<span className="text-red-600">*</span>
                    </label>
                    <input
                        type="text"
                        name="vendor_area"
                        placeholder="Enter Area"
                        onChange={handleInputChange}
                        value={inputs.vendor_area}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        City<span className="text-red-600">*</span>
                    </label>
                    <select
                        name="vendor_city"
                        onChange={(e) => handleSelectChange(e, "vendor_city")}
                        value={inputs.vendor_city}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Select City</option>
                        {citylist.map((city) => (
                            <option key={city._id} value={city._id}>
                                {city.city_name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        GST No.<span className="text-red-600">*</span>
                    </label>
                    <input
                        type="text"
                        name="vendor_gstno"
                        placeholder="Enter GST No."
                        onChange={handleInputChange}
                        value={inputs.vendor_gstno}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Password<span className="text-red-600">*</span>
                    </label>
                    <input
                        type="password"
                        name="vendor_password"
                        placeholder="Enter Password"
                        onChange={handleInputChange}
                        value={inputs.vendor_password}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="flex gap-4">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                    >
                        Submit
                    </button>
                    <button
                        type="reset"
                        onClick={resetHandler}
                        className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                    >
                        Reset
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PartyForm;