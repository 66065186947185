import React from "react";
import SideNav from "./components/common/SideNav";
import TopNav from "./components/common/TopNav";
import { Routes, Route } from "react-router-dom";
import About from './components/About';
import Home from './components/Home';
import AddIndentForm from './components/AddIndentForm';
import QuoteComparison from "./components/QuoteComparison";
import CreatePOForm from "./components/CreatePOForm";
import ApprovalList from "./components/ApprovalList";
import RateComparison from "./components/RateComparison";
import AddPurchaseDetails from "./components/AddPurchaseDetails";
import CreatedPOList from "./components/CreatedPOList";
import Dashboard from "./components/Dashboard";
import Login from "./components/common/Login";
import AuthRouter from "./components/AuthRouter";
import IndentEdit from "./components/IndentEdit";
import PurchaseEntry from "./components/purchase-payment/PurchaseEntry";
import PurchasePayment from "./components/purchase-payment/PurchasePayment";
import ProductionForm from "./components/ProductionForm";
import ConsumptionForm from "./components/ConsumptionForm";
import IndentDetails from "./components/IndentDetails";
import QuotationComparisonDetail from "./components/QuotationComparisonDetail";
import ReturnList from "./components/ReturnList";
import PurchaseEdit from "./components/PurchaseEdit";
import ReceiptNoteFrom from "./components/receipt_not/ReceiptNoteFrom";
import ReceiptNoteReport from "./components/receipt_not/ReceiptNoteReport ";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import Report from "./components/Report";
import ReportInfo from "./components/ReportInfo";
import AddItems from "./components/Master/AddItems";
import AddCategory from "./components/Master/AddCategory";
import MaterialInOutReport from "./components/MaterialInOut/MaterialInOut";
import MaterialInOutForm from "./components/MaterialInOut/MaterialInOutForm";
import StockIssue from "./components/StockIssue";
import StockIssueForm from "./components/StockIssueForm";
import MaterialInOutView from "./components/MaterialInOut/MaterialInOutView";
import StockIssueCategoryItems from "./components/StockIssueCategoryItems";
import AddParty from "./components/Master/AddParty";
import FormatPdf from "./components/FormatPdf";

const App = () => {

  const currentUser = useSelector((state) => state.user.staff_name)
  const token = Cookies.get('access_token');

  return (
    <main style={{ display: "flex", background: "#EDEEF7" }}>
      {currentUser && <SideNav />}
      <div className="flex flex-1 flex-col h-screen w-full overflow-y-scroll main-content ">
        {currentUser && <TopNav />}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<AuthRouter />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/about' element={<About />} />
            <Route index element={<Home />} />
            <Route path='/indentForm' element={<AddIndentForm />} />
            <Route path='/pdf' element={<FormatPdf/>} />
            <Route path='/returnList' element={<ReturnList />} />
            <Route path='/return-purchase-edit/:id' element={<PurchaseEdit />} />
            <Route path="/details/:id" element={<IndentDetails />} />
            <Route path='/add-purchase-details/' element={<AddPurchaseDetails />} />
            <Route path='/quote-comparison' element={<QuoteComparison />} />
            <Route path='/quote-comparison-detail/:id' element={<QuotationComparisonDetail />} />
            <Route path='/approval-list' element={<ApprovalList />} />
            <Route path='/create-po-form' element={<CreatePOForm />} />
            <Route path='/created-po-list' element={<CreatedPOList />} />
            <Route path='/rate-comparison' element={<RateComparison />} />
            <Route path='/receipt-note-report' element={<ReceiptNoteReport />} />
            <Route path='/create-receipt-form' element={<ReceiptNoteFrom />} />
            <Route path="/edit/:id" element={<IndentEdit />} />
            <Route path='/purchase-entry' element={<PurchaseEntry />} />
            <Route path='/purchase-payment' element={<PurchasePayment />} />
            <Route path='/consumption-form' element={<ConsumptionForm />} />
            <Route path='/production-form' element={<ProductionForm />} />
            <Route path='/report' element={<Report />} />
            <Route path='/report-info/:date' element={<ReportInfo />} />
            <Route path='/add-items' element={<AddItems />} />
            <Route path='/add-party' element={<AddParty />} />
            <Route path='/add-category' element={<AddCategory />} />
            <Route path='/material-in-out' element={<MaterialInOutReport />} />
            <Route path='/material-in-out-form' element={<MaterialInOutForm />} />
            <Route path='/material-in-out-view/:id' element={<MaterialInOutView />} />
            <Route path='/stock-issue' element={<StockIssue />} />
            <Route path='/stock-issue/:id' element={<StockIssueCategoryItems />} />
            <Route path='/stock-issue-form' element={<StockIssueForm />} />
          </Route>
        </Routes>
      </div>
    </main>
  );
};

export default App;
