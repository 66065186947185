import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import Button from '@mui/material/Button';
import api from './Axios/api';
import { useNavigate } from 'react-router-dom';
const data = [
    {
        "date": "2024-12-12",
        "production": [
            {
                "voucherNo": "520",
                "addDate": "2024-12-12T00:00:00.000Z",
                "totalAmount": 75360,
                "consume_items": [
                    {
                        "_id": "6756d20139df5346c1e42aa9",
                        "itemName": "SPRING WASHER",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 120,
                        "rate": 520,
                        "amount": 62400
                    },
                    {
                        "_id": "6756d20139df5346c1e42aaa",
                        "itemName": "CARBON BRUSH AG 7",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 120,
                        "rate": 108,
                        "amount": 12960
                    }
                ]
            }
        ],
        "consumption": [
            {
                "voucherNo": "120",
                "addDate": "2024-12-12T00:00:00.000Z",
                "totalAmount": 16660,
                "consume_items": [
                    {
                        "_id": "6756d1e439df5346c1e42a98",
                        "itemName": "SPRING WASHER",
                        "godown": "POLYBOND INSULATION",
                        "quantity": 20,
                        "rate": 521,
                        "amount": 10420
                    },
                    {
                        "_id": "6756d1e439df5346c1e42a99",
                        "itemName": "BIT SOCKET TAPARIA",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 520,
                        "rate": 12,
                        "amount": 6240
                    }
                ]
            }
        ]
    },
    {
        "date": "2024-12-09",
        "production": [
            {
                "voucherNo": "1545",
                "addDate": "2024-12-09T00:00:00.000Z",
                "totalAmount": 135000,
                "consume_items": [
                    {
                        "_id": "6756d14739df5346c1e42a7b",
                        "itemName": "Gi Nipple 3/4x3\"",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 150,
                        "rate": 60,
                        "amount": 9000
                    },
                    {
                        "_id": "6756d14739df5346c1e42a7c",
                        "itemName": "CI Ballvalve 1.1/2\"",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 210,
                        "rate": 600,
                        "amount": 126000
                    }
                ]
            }
        ],
        "consumption": [
            {
                "voucherNo": "1552",
                "addDate": "2024-12-09T00:00:00.000Z",
                "totalAmount": 4216.82,
                "consume_items": [
                    {
                        "_id": "6756d12339df5346c1e42a6a",
                        "itemName": "HT Bolt 10mmx30mm",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 10,
                        "rate": 50,
                        "amount": 500
                    },
                    {
                        "_id": "6756d12339df5346c1e42a6b",
                        "itemName": "C.Box G.Color 3Ply Plain Insu. 610x510x1020",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 54,
                        "rate": 68.83,
                        "amount": 3716.8199999999997
                    }
                ]
            }
        ]
    },
    {
        "date": "2024-12-07",
        "production": [
            {
                "voucherNo": "120",
                "addDate": "2024-12-07T00:00:00.000Z",
                "totalAmount": 10047.6,
                "consume_items": [
                    {
                        "_id": "67568f0a39df5346c1e42914",
                        "itemName": "H T Spring Washer 12 mm",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 540,
                        "rate": 1.94,
                        "amount": 1047.6
                    },
                    {
                        "_id": "67568f0a39df5346c1e42915",
                        "itemName": "Oil Seal 65x80x8",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 150,
                        "rate": 60,
                        "amount": 9000
                    }
                ]
            }
        ],
        "consumption": [
            {
                "voucherNo": "123",
                "addDate": "2024-12-07T00:00:00.000Z",
                "totalAmount": 52740,
                "consume_items": [
                    {
                        "_id": "67568ebe39df5346c1e428fe",
                        "itemName": "V Belt B 52",
                        "godown": "POLYBOND ROCKFIBRE",
                        "quantity": 210,
                        "rate": 150,
                        "amount": 31500
                    },
                    {
                        "_id": "67568ebe39df5346c1e428ff",
                        "itemName": "V Belt B 55",
                        "godown": "POLYBOND INSULATION",
                        "quantity": 120,
                        "rate": 177,
                        "amount": 21240
                    }
                ]
            }
        ]
    },
    {
        "date": "2024-12-06",
        "production": [
            {
                "voucherNo": "120",
                "addDate": "2024-12-06T00:00:00.000Z",
                "totalAmount": 14640,
                "consume_items": [
                    {
                        "_id": "67568ef139df5346c1e4290f",
                        "itemName": "SPRING WASHER",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 120,
                        "rate": 120,
                        "amount": 14400
                    },
                    {
                        "_id": "67568ef139df5346c1e42910",
                        "itemName": "H T Nut 8 mm",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 120,
                        "rate": 2,
                        "amount": 240
                    }
                ]
            }
        ],
        "consumption": [
            {
                "voucherNo": "123",
                "addDate": "2024-12-06T00:00:00.000Z",
                "totalAmount": 38940,
                "consume_items": [
                    {
                        "_id": "67568e9039df5346c1e428f9",
                        "itemName": "BIT SOCKET SET TAPARIA  1/2\" SQ. DR.",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 120,
                        "rate": 120,
                        "amount": 14400
                    },
                    {
                        "_id": "67568e9039df5346c1e428fa",
                        "itemName": "V Belt B50",
                        "godown": "POLYBOND PROJECTS",
                        "quantity": 120,
                        "rate": 204.5,
                        "amount": 24540
                    }
                ]
            }
        ]
    }
]
const Report = () => {
    // Transform data
    const [reportData, setReportData] = useState();

    const filterFun = (data) => {
        const filteredData = data.map((entry) => {
            let itemSource = null;

            let consumptionTotalAmount = entry.consumption?.[0]?.totalAmount || 0;
            let productionTotalAmount = entry.production?.[0]?.totalAmount || 0;
            let ovarAllTotal = `${consumptionTotalAmount} || ${productionTotalAmount}`;

            if (entry.consumption?.length > 0) {
                const firstConsumption = entry.consumption[0];
                itemSource = {
                    date: entry.date,
                    voucherNo: firstConsumption.voucherNo,
                    itemName: firstConsumption.consume_items?.[0]?.itemName || "N/A",
                    totalAmount: ovarAllTotal,
                    info: "Consumption Info",
                };
            } else if (entry.production?.length > 0) {
                const firstProduction = entry.production[0];
                itemSource = {
                    date: entry.date,
                    voucherNo: firstProduction.voucherNo,
                    itemName: firstProduction.consume_items?.[0]?.itemName || "N/A",
                    totalAmount: ovarAllTotal,
                    info: "Production Info",
                };
            }

            return itemSource;
        });

        setReportData(filteredData);
    };



    // const filteredData = data.map((entry) => {
    //     let itemSource = null;

    //     // Check for consumption array first
    //     let consumptionTotalAmount = entry.consumption[0].totalAmount || 0;
    //     let productionTotalAmount = entry.production[0].totalAmount || 0;
    //     let ovarAllTotal = consumptionTotalAmount + "||" + productionTotalAmount;
    //     if (entry.consumption.length > 0) {
    //         const firstConsumption = entry.consumption[0];
    //         itemSource = {
    //             date: entry.date,
    //             voucherNo: firstConsumption.voucherNo,
    //             itemName: firstConsumption.consume_items[0]?.itemName || "N/A",
    //             totalAmount: ovarAllTotal,
    //             info: "Consumption Info",
    //         };
    //     }
    //     // If consumption is empty, check production array
    //     else if (entry.production.length > 0) {
    //         const firstProduction = entry.production[0];
    //         itemSource = {
    //             date: entry.date,
    //             voucherNo: firstProduction.voucherNo,
    //             itemName: firstProduction.consume_items[0]?.itemName || "N/A",
    //             totalAmount: ovarAllTotal,
    //             info: "Production Info",
    //         };
    //     }

    //     return itemSource;
    // });


    const fetchData = async () => {
        try {
            const response = await api(`consumption/get-consumptions`);
            const data = response.data;
            filterFun(data)
        } catch (error) {
            console.log(error)
        }
    }
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, [])
    // Define columns
    const columns = useMemo(() =>
        [
            {
                accessorKey: 'date',
                header: 'Date',
                size: 300
            },
            {
                accessorKey: 'voucherNo',
                header: 'Voucher No.',
                size: 300
            },
            {
                accessorKey: 'itemName',
                header: 'Item Name',
                size: 450
            },
            {
                accessorKey: 'totalAmount',
                header: 'Total Amount',
                Cell: ({ cell }) => {
                    return `₹ ${cell.getValue()}`; // Format as currency
                },
                size: 300
            },
            {
                accessorKey: 'date',
                header: 'Info',
                Cell: ({ cell, row }) => (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => navigate(`/report-info/${cell.getValue()}`)}
                    >
                        Info
                    </Button>
                ),
                size: 300,
            },
        ], [reportData]);


    return (
        <div className='w-full flex justify-center'>
            <div className='w-[98%] mt-4'>
                <MaterialReactTable
                    columns={columns}
                    data={reportData || []}
                    enableRowSelection
                />
            </div>
        </div>
    );
};

export default Report;
