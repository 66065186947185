import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import api from "../Axios/api";
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import Swal from "sweetalert2";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PurchaseEntry() {
  const [purchaseEntryList, setPurchaseEntryList] = useState([]);
  const [rowData, setRowData] = useState({});
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    receipt_id: "",
    invoice_date: "",
    invoice_number: "",
    grn_value: 0,
    dcr_type: "null",
    dcr_amount: 0,
    total_amount: 0,
    remark: "",
  });

  const getPurchaseEntryList = async () => {
    try {
      const response = await api.get("/purchase-payment/get-purchase-entry");
      setPurchaseEntryList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "receipt_no",
        header: "Receipt No.",
        size: 100
      },
      {
        accessorKey: "entry_date",
        header: "Receipt Date",
        size: 120,
        Cell: ({ cell }) => {
          return moment(cell.getValue()).format('DD/MM/YYYY'); // You can change the format to 'DD/MM/YYYY' or any other format
        },
      },
      {
        accessorKey: "vendor_name",
        header: "Party Name",
        size: 200,
      },
      {
        accessorKey: "po_number",
        header: "PO No.",
        size: 150,
        Cell: ({ cell }) => (
          <div className="max-h-10 overflow-y-auto">
            {cell.getValue().map(poNo => (
              <div key={poNo}>{poNo}</div>
            ))}
          </div>
        ),
      },
      {
        header: "Status",
        size: 250,
        Cell: ({ cell, row }) => (
          <div className="max-h-10 overflow-y-auto">
            {/* <button type="button" className={`text-white ${"Condition here..." ? 'bg-green-600 hover:bg-green-700' : 'bg-red-600 hover:bg-red-700'} font-medium rounded text-xs px-1.5 py-0.5 m-1`}>30 D</button> */}
            <button type="button" className={`text-white ${new Date() - new Date(row.original.entry_date) < 30 * 24 * 60 * 60 * 1000 ? 'bg-red-600 hover:bg-red-700' : 'bg-green-600 hover:bg-green-700'} font-medium rounded text-xs px-1.5 py-0.5 m-1`}>30 D</button>
            <button type="button" className={`text-white ${row.original.receipt_quality_img ? 'bg-green-600 hover:bg-green-700' : 'bg-red-600 hover:bg-red-700'} font-medium rounded text-xs px-1.5 py-0.5 m-1`}>LAB</button>
            <button type="button" className={`text-white ${row.original.receipt_weight_slip_img ? 'bg-green-600 hover:bg-green-700' : 'bg-red-600 hover:bg-red-700'} font-medium rounded text-xs px-1.5 py-0.5 m-1`}>WTS</button>
            <button type="button" className={`text-white ${row.original.stores_remarks ? 'bg-green-600 hover:bg-green-700' : 'bg-red-600 hover:bg-red-700'} font-medium rounded text-xs px-1.5 py-0.5 m-1`}>ST-RE</button>
            <button type="button" className={`text-white ${row.original.purchase_count > 2 ? 'bg-green-600 hover:bg-green-700' : 'bg-red-600 hover:bg-red-700'} font-medium rounded text-xs px-1.5 py-0.5 m-1`}>COM-{row.original.purchase_count}</button>
          </div>
        ),
      },
      {
        accessorKey: "_id",
        header: "Action",
        size: 100,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <button
              type="button"
              className="text-white bg-gradient-to-r from-violet-500 via-violet-600 to-violet-700 hover:bg-gradient-to-br focus:outline-none font-medium rounded-lg text-sm px-5 py-1.5 text-center me-2 mb-2"
              onClick={() => { handleModalOpen(row) }}
            >
              Proceed
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: purchaseEntryList,
  });

  const handleModalOpen = (rowData) => {
    setRowData(rowData.original);
    setOpen(true);
    setFormState((prev) => ({
      ...prev,
      receipt_id: rowData.original._id,
      grn_value: rowData.original.receipt_total_amount,
      total_amount: rowData.original.receipt_total_amount,
    }))
  };

  const handleModalClose = () => {
    setOpen(false);
    setErrors({});
    setFormState({
      receipt_id: "",
      invoice_date: "",
      invoice_number: "",
      grn_value: 0,
      dcr_type: "null",
      dcr_amount: 0,
      total_amount: 0,
      remark: "",
    });
  };

  const handleCalculation = (val) => {
    setFormState((prev) => {
      let newDcrAmount = Number(val);
      let total = 0;

      if (prev.dcr_type === "credit") {
        total = Number(prev.grn_value) + newDcrAmount;
      } else if (prev.dcr_type === "debit") {
        total = Number(prev.grn_value) - newDcrAmount;
      } else {
        total = Number(prev.grn_value);
      }
      return { ...prev, dcr_amount: newDcrAmount, total_amount: total };
    });
  }

  const validateForm = () => {
    const newErrors = {};
    if (!formState.receipt_id) newErrors.receipt_id = 'Receipt id is required';
    if (!formState.invoice_date) newErrors.invoice_date = 'Invoice Date is required';
    if (!formState.invoice_number) newErrors.invoice_number = 'Invoice number is required';
    if (!formState.grn_value) newErrors.grn_value = 'GRN value is required';
    // if (!formState.dcr_amount) newErrors.dcr_amount = 'Debit. Credit. amount is required';
    if (!formState.total_amount) newErrors.total_amount = 'Total Amount is required';
    return newErrors;
  };

  const handleSubmit = (e) => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setOpen(false);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Submit it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api.post("/purchase-payment/add", formState).then((result) => {
          Swal.fire({
            title: "Submitted Success!",
            text: "Your file has been submitted successfully.",
            icon: "success",
            timer: 1500
          });
          handleModalClose();
          getPurchaseEntryList();
        }).catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "There was an error submitting the form: " + error.response.data,
            icon: "error"
          }).then(() => {
            setOpen(true);
          });
        });
      } else {
        setOpen(true);
      }
    });
  };

  useEffect(() => {
    getPurchaseEntryList();
  }, []);

  useEffect(() => {
    handleCalculation(formState.dcr_type === "null" ? 0 : formState.dcr_amount);
  }, [formState.dcr_type]);

  return (
    <div className="flex-1 px-5 w-full justify-center items-center">
      <div>
        <div className="bg-[#fdfdfd] border mt-4 p-2 flex w-full justify-between items-center rounded-md shadow">
          <div className="flex rounded-md items-center w-1/2">
            <h2 className="font-bold text-[#7286D3]">Purchase Entry</h2>
          </div>
        </div>
      </div>
      <div className="w-full min-h-[500px] my-2 rounded-md shadow bg-[#fdfdfd]">
        <MaterialReactTable table={table} />
      </div>

      {/* Modal Section */}
      <React.Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleModalClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth={'md'}
        >
          <DialogTitle style={{ fontWeight: 'bold', textAlign: 'center' }} className="border-b">Purchase Entry</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="flex flex-wrap gap-1 my-3">
                <div className="w-[48%] text-black"><strong>Receipt Date:</strong> {moment(rowData.entry_date).format('DD/MM/YYYY')}</div>
                <div className="w-[48%] text-black"><strong>Receipt No.:</strong> {rowData.receipt_no}</div>
                <div className="w-[48%] text-black"><strong>Party Name:</strong> {rowData.vendor_name}</div>
                <div className="w-[48%] text-black"><strong>PO No.:</strong> {rowData.po_number?.join(',')}</div>
              </div>

              <div className="grid grid-cols-2 gap-6 mb-3">
                <div className="flex flex-col">
                  <label className="font-semibold text-gray-700">
                    Invoice Date <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    name="invoice_date"
                    value={formState.invoice_date}
                    onChange={(e) => { setFormState((prev) => ({ ...prev, invoice_date: e.target.value })) }}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    placeholder="Invoice Date"
                  />
                  {errors.invoice_date && <div className="text-sm text-red-500 font">{errors.invoice_date}</div>}
                </div>

                <div className="flex flex-col">
                  <label className="font-semibold text-gray-700">
                    Party Invoice No. <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="invoice_number"
                    value={formState.invoice_number}
                    onChange={(e) => { setFormState((prev) => ({ ...prev, invoice_number: e.target.value })) }}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    placeholder="Party Invoice No."
                  />
                  {errors.invoice_number && <div className="text-sm text-red-500 font">{errors.invoice_number}</div>}
                </div>
              </div>

              <div className="grid grid-cols-3 gap-4">
                <div className="flex flex-col">
                  <label className="font-semibold text-gray-700">
                    GRN Value <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    disabled={true}
                    name="grn_value"
                    value={formState.grn_value}
                    // onChange={(e) => { setFormState((prev) => ({ ...prev, grn_value: e.target.value })) }}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    placeholder="Receipt GRN value"
                  />
                  {errors.grn_value && <div className="text-sm text-red-500 font">{errors.grn_value}</div>}
                </div>

                <div className="flex flex-col">
                  <div className="m-0.5 flex gap-3 flex-wrap justify-center">
                    <div className="flex items-center">
                      <input
                        onChange={(e) => { setFormState((prev) => ({ ...prev, dcr_type: 'credit' })) }}
                        id="green-radio"
                        type="radio"
                        name="colored-radio"
                        className="w-4 h-4 bg-gray-100 border-gray-300"
                        checked={formState.dcr_type === 'credit'}
                      />
                      <label htmlFor="green-radio" className="ms-0.5 text-sm font-bold text-red-900">Credit</label>
                    </div>

                    <div className="flex items-center">
                      <input
                        onChange={(e) => { setFormState((prev) => ({ ...prev, dcr_type: 'debit' })) }}
                        id="red-radio"
                        type="radio"
                        name="colored-radio"
                        className="w-4 h-4 bg-gray-100 border-gray-300"
                        checked={formState.dcr_type === 'debit'}
                      />
                      <label htmlFor="red-radio" className="ms-0.5 text-sm font-bold text-green-900">Debit</label>
                    </div>

                    <div className="flex items-center">
                      <input
                        onChange={(e) => { setFormState((prev) => ({ ...prev, dcr_type: 'null' })) }}
                        id="red-none"
                        type="radio"
                        name="colored-radio"
                        className="w-4 h-4 bg-gray-100 border-gray-300"
                        checked={formState.dcr_type === 'null'}
                      />
                      <label htmlFor="red-none" className="ms-0.5 text-sm font-bold">null</label>
                    </div>
                  </div>
                  <input
                    type="number"
                    disabled={formState.dcr_type === 'null' || formState.dcr_type === ""}
                    name="dcr_amount"
                    value={formState.dcr_amount}
                    onChange={(e) => handleCalculation(e.target.value)}
                    className={`${formState.dcr_type === 'credit' ? 'ring-red-400' : formState.dcr_type === 'debit' ? 'ring-green-500' : 'ring-gray-300'} block w-full rounded border-0 py-1.5 pl-7 pr-10 ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                  />
                  {errors.dcr_amount && <div className="text-sm text-red-500 font">{errors.dcr_amount}</div>}
                </div>

                <div className="flex flex-col">
                  <label className="font-semibold text-gray-700">
                    Total Amount <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    disabled={true}
                    name="total_amount"
                    value={formState.total_amount}
                    // onChange={(e) => { setFormState((prev) => ({ ...prev, total_amount: e.target.value })) }}
                    className="block w-full rounded border-0 py-1.5 pl-7 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    placeholder="Total amount"
                  />
                  {errors.total_amount && <div className="text-sm text-red-500 font">{errors.total_amount}</div>}
                </div>
              </div>

              <div className="flex flex-col mt-6">
                <label className="mb-2 font-semibold text-gray-700">
                  Remark
                </label>
                <textarea
                  name="remark"
                  value={formState.remark}
                  onChange={(e) => { setFormState((prev) => ({ ...prev, remark: e.target.value })) }}
                  className="block w-full rounded border-0 py-1.5 pl-7 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Remark"
                  rows="4"
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
}

export default PurchaseEntry;
