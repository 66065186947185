import React, { useEffect, useRef, useState } from "react";
import api from "../Axios/api";
import Swal from "sweetalert2";
import ReceiptPartyDetail from "./ReceiptFormPartyDetail";
import ReceiptFormItems from "./ReceiptFormItems";
import ReceiptFormOtherDetail from "./ReceiptFormOtherDetail";
import ReceiptFormImages from "./ReceiptFormImages";
import BackBtn from "../common/BackBtn";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import ReceiptEditData from "./ReceiptEditData";
import moment from "moment/moment";

const ReceiptNoteFrom = () => {
  const location = useLocation();
  const receiptId = location?.state?.receiptId;
  const navigate = useNavigate();
  const formRef = useRef(null);
  const extraGstOptions = [
    { label: "0%", value: 0 },
    { label: "5%", value: 5 },
    { label: "12%", value: 12 },
    { label: "18%", value: 18 },
    { label: "28%", value: 28 }
  ];

  const initialReceiptItems =
  {
    purchase_id: "",
    receipt_item_id: "",
    receipt_item_qty: 0,
    receipt_item_rem_qty: 0,
    receipt_item_rate: 0,
    receipt_item_disc: 0,
    receipt_item_gst: 0,
    receipt_item_total_without_gst: 0,
    receipt_item_total_with_gst: 0,
    receipt_item_remark: "",
  };

  const initialFormState = {
    entry_date: moment().format('yyyy-MM-DD'),
    receipt_no: "",
    staff_id: "",
    party_id: "",
    po_number: [],
    po_id: [],
    purchase_ledger: "",
    order_date: [],
    receipt_status: "Accepted",
    terms_of_payment: "",
    other_references: "",
    terms_of_delivery: "",
    receipt_doc_no: "",
    dispatched_through: "",
    destination: "",
    carrier_name: "",
    bill_of_lading: "",
    motor_vehicle_no: "",
    insurance_policy_no: "",
    contact_person: "",
    vehicle_no: "",
    driver_mobile_no: "",
    challan_no: "",
    weight_slip_no: "",
    weight_slip_date: "",
    fright_advice_no: "",
    stores_remarks: "",
    challan_date: "",
    gate_entry_no: "",
    fright_rs: "",
    receipt_challan_img: [],
    receipt_weight_slip_img: [],
    receipt_item_img: [],
    receipt_invoice_img: [],
    receipt_quality_img: [],
    receipt_weight_kg: "",
    receipt_narration: "",
    receipt_total_without_gst: 0,
    receipt_total_with_gst: 0,

    receipt_charge_name1: "",
    receipt_charge_amount1: 0,
    receipt_charge_gst1: 0,
    receipt_total_with_charge1: 0,

    receipt_charge_name2: "",
    receipt_charge_amount2: 0,
    receipt_charge_gst2: 0,
    receipt_total_with_charge2: 0,

    receipt_total_amount: 0,
  }

  // Dropdown States
  const [poNoDropdown, setPoNoDropdown] = useState([{ value: null, label: "Cash", }]);
  const [orderDateDropDown, setOrderDateDropDown] = useState([]);
  const [itemGroupDropDown, setItemGroupDropDown] = useState([]);
  const [purchaseItemDropdown, setPurchaseItemDropdown] = useState([]);
  const [extraChargesDropdown, setExtraChargesDropdown] = useState([]);
  const [isCase, setIsCase] = useState(true);
  const [checkedItems, setCheckedItems] = useState([]);
  const [receiptItems, setReceiptItems] = useState([initialReceiptItems]);
  const [receiptImgUrl, setReceiptImgUrl] = useState({});

  // Form State
  const [formState, setFormState] = useState(initialFormState);

  // console.log(formState);

  // Fetch Item Groups
  const getItemGroup = async () => {
    try {
      const res = await api.get(`/purchase-item-category/get-name`);
      console.log("Item Group", res.data);
      const newData = res.data.map(cat => ({
        value: cat._id, label: cat.itemcat_title
      }));
      setItemGroupDropDown(newData);
    } catch (error) {
      console.error(error.response?.data || error);
    }
  };

  // Fetch Purchase Item List
  const getPurchaseItemList = async (id) => {
    try {
      const res = await api.get(`/purchase-item/get-item-name/${id || ""}`);
      console.log("Purchase Item", res.data);
      const newData = res.data.map(item => ({
        value: item._id, label: item.item_title
      }));
      setPurchaseItemDropdown(newData);
    } catch (error) {
      console.error(error.response?.data || error);
    }
  };

  const getExtraChargesList = async () => {
    try {
      const { data } = await api.get(`/extra-charges/get`);
      console.log("Extra Charges", data);
      const newData = data.extraCharges.map(item => ({ value: item._id, label: item.charge_name }));
      setExtraChargesDropdown(newData);
    } catch (error) {
      console.error(error);
    }
  };

  const getPoDetail = async (poId) => {
    try {
      const { data } = await api.get(`/receipt-note/get-po-detail/${poId.join(",")}`);

      // Order date dropdown
      const dates = data.map(value => {
        const date = new Date(value.po_date).toISOString().split("T")[0];
        return { value: date, label: date };
      });
      setOrderDateDropDown(dates);

      // Set form details
      const [firstData] = data;
      setFormState(prev => ({
        ...prev,
        terms_of_payment: firstData.terms_of_payment,
        other_references: firstData.fright,
        terms_of_delivery: firstData.delivery_terms,
        purchase_ledger: firstData.purchase_ledger
      }));

      // Item list implementation
      setReceiptItems([]);
      setCheckedItems([]);
      const newReceiptItems = data.flatMap(poData => poData.items.map(item => ({
        purchase_id: item.purchase_id,
        receipt_item_id: item.item_id,
        receipt_item_qty: Number(item.purchase_remaining_qty) || Number(item.purchase_qty),
        receipt_item_rem_qty: 0,
        receipt_item_rate: item.rate,
        receipt_item_disc: item.discount,
        receipt_item_gst: item.item_gst,
        receipt_item_total_without_gst: item.total_without_gst,
        receipt_item_total_with_gst: item.total_with_gst,
        receipt_item_remark: item.stock_desc,
      })));
      setReceiptItems(newReceiptItems);
      setIsCase(false);
    } catch (error) {
      console.log(error)
    }
  }

  const deleteItem = (index) => {
    setReceiptItems(prev => [...prev.filter((_, i) => i !== index)]);
  };

  const addItem = () => {
    setReceiptItems(prevItems => [...prevItems, initialReceiptItems]);
  };

  // -----------Start Calculate the total amount of items with add all charges-----------
  const calculateTotalCharges = (chargeAmount, gst) => (
    Number(chargeAmount) + (Number(chargeAmount) * Number(gst) / 100)
  );

  useEffect(() => {
    const withCharge1 = calculateTotalCharges(formState.receipt_charge_amount1, formState.receipt_charge_gst1);
    setFormState(prev => ({ ...prev, receipt_total_with_charge1: withCharge1.toFixed(2) }));
  }, [formState.receipt_charge_amount1, formState.receipt_charge_gst1]);

  useEffect(() => {
    const withCharge2 = calculateTotalCharges(formState.receipt_charge_amount2, formState.receipt_charge_gst2);
    setFormState(prev => ({ ...prev, receipt_total_with_charge2: withCharge2.toFixed(2) }));
  }, [formState.receipt_charge_amount2, formState.receipt_charge_gst2]);

  useEffect(() => {
    let totalAmount = Number(formState.receipt_total_with_gst) + Number(formState.receipt_total_with_charge1) + Number(formState.receipt_total_with_charge2)
    setFormState((prev) => ({ ...prev, receipt_total_amount: totalAmount.toFixed(2) }));
  }, [formState.receipt_total_with_gst, formState.receipt_total_with_charge1, formState.receipt_total_with_charge2]);
  // -----------End Calculate the total amount of items with add all charges-----------

  // calculate overall item price with gst and without gst
  useEffect(() => {
    const totalWithGst = receiptItems.reduce((acc, item) => acc + Number(item.receipt_item_total_with_gst), 0);
    const totalWithoutGst = receiptItems.reduce((acc, item) => acc + Number(item.receipt_item_total_without_gst), 0);
    setFormState(prev => ({
      ...prev,
      receipt_total_without_gst: totalWithoutGst,
      receipt_total_with_gst: totalWithGst,
    }));
  }, [receiptItems]);

  // when change po_id (po_number) get full po detail with item list
  useEffect(() => {
    if (!receiptId) {
      if (formState?.po_id?.length > 0) {
        getPoDetail(formState.po_id);
      } else {
        setReceiptItems([initialReceiptItems]);
        setOrderDateDropDown([]);
        setFormState((prev) => ({ ...prev, order_date: [] }));
        setIsCase(true);
      }
    }
  }, [formState.po_id]);

  // it is work when edit the receipt form and get all receipt data
  useEffect(() => {
    if (receiptId) {
      setIsCase(false);
      ReceiptEditData({ receiptId, setFormState, setPoNoDropdown, setReceiptImgUrl, setOrderDateDropDown, setReceiptItems });
    }
  }, [receiptId]);

  useEffect(() => {
    getItemGroup();
    getPurchaseItemList();
    getExtraChargesList();
  }, []);

  const submitAlertMsg = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Submited it!"
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit();
      }
    });
  }

  const validateForm = () => {
    const requireImgField = ["receipt_challan_img", "receipt_invoice_img"];
    const requiredFields = [
      "receipt_no", "entry_date", "staff_id", "party_id", "po_number",
      "po_id", "purchase_ledger", "order_date", "receipt_status",
      "terms_of_payment", "other_references", "terms_of_delivery",
      "challan_no", "stores_remarks", "challan_date", "gate_entry_no"];
    let isValid = requiredFields.every(field => formState[field]);
    if (!isValid) {
      Swal.fire({
        title: "Error!",
        text: "Please fill out all required fields.",
        icon: "error",
        confirmButtonText: "Okay",
      });
      return false;
    }
    if (!receiptId) {
      isValid = requireImgField.every(field => formState[field]);
    }
    if (!isValid) {
      Swal.fire({
        title: "Error!",
        text: "Please fill out all required fields.",
        icon: "error",
        confirmButtonText: "Okay",
      });
      return false;
    }
    return true;
  };

  const handleCheck = (e) => {
    const isChecked = e.target.checked;
    const index = Number(e.target.value);
    isChecked ? setCheckedItems(prev => [...prev, index])
      : setCheckedItems(prev => [...prev.filter((val, i) => val !== index)]);
  }

  const handleSubmit = async () => {
    if (!validateForm()) return;

    // Filter receipt items based on checked indexes
    const filteredItems = isCase ? receiptItems : receiptItems.filter((item, index) => checkedItems.includes(index));
    try {
      const dataToSubmit = { ...formState, receiptItems: filteredItems }
      // console.log(dataToSubmit);

      if (receiptId && isNaN(receiptId)) {
        await api.patch(`/receipt-note/update/${receiptId}`, dataToSubmit, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Receipt note updated successfully!',
          timer: 1500,
        });
      } else {
        await api.post("/receipt-note/add", dataToSubmit, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Receipt note added successfully!',
          timer: 1500,
        });
      }
      navigate('/receipt-note-report');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while submitting the form. Please try again' + error,
      });
    } finally {
    }
  };

  const handleResetForm = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset it!"
    }).then((result) => {
      if (result.isConfirmed) {
        formRef.current.reset(); // Reset all form fields to their default values
        setCheckedItems([]);
        setIsCase(true);
        setFormState(initialFormState);
        setReceiptItems([initialReceiptItems]);
        setPoNoDropdown([{ value: null, label: "Cash", }]);
        Swal.fire({
          title: "Reset Form!",
          text: "Your form has been reset success.",
          icon: "success"
        });
      }
    });
  };

  return (
    <div className="flex-1 px-5 w-full mx-auto p-4">
      <div className="h-10 px-2 flex justify-between items-center text-violet-500 font-bold rounded-md bg-[#fdfdfd] shadow mb-2">
        <div className="text-lg">Receipt Note From</div>
        <BackBtn />
      </div>
      <form ref={formRef}>
        <div className="w-full min-h-[500px] px-6 py-4 my-2 rounded-md shadow bg-[#fdfdfd]">
          <div>
            <h2 className="text-xl font-semibold px-6 mb-4 text-center my-4">Party Details</h2>
            <ReceiptPartyDetail
              formState={formState}
              setFormState={setFormState}
              orderDateDropDown={orderDateDropDown}
              poNoDropdown={poNoDropdown}
              setPoNoDropdown={setPoNoDropdown}
              isCase={isCase}
              receiptId={receiptId}
            />

            <div id="itemList" className="mt-6">
              {receiptItems.map((item, index) => (
                <div className="flex justify-evenly items-center" key={index}>
                  {!isCase &&
                    <input
                      type="checkbox"
                      id="checkbox"
                      onClick={handleCheck}
                      value={index}
                      className="w-5 h-5 text-blue-600 bg-gray-100 rounded border-gray-300 "
                    />
                  }
                  <ReceiptFormItems
                    items={item}
                    index={index}
                    receiptItems={receiptItems}
                    setReceiptItems={setReceiptItems}
                    addItem={addItem}
                    deleteItem={deleteItem}
                    isCase={isCase}
                    checkedItems={checkedItems}
                    itemGroupDropDown={itemGroupDropDown}
                    purchaseItemDropdown={purchaseItemDropdown}
                    setPurchaseItemDropdown={setPurchaseItemDropdown}
                  />
                </div>
              ))}
              {!isCase &&
                <>
                  <div className=" flex mx-auto justify-center gap-3 py-5 ">
                    <div>
                      <label htmlFor="">Total Amount Without GST</label>
                      <input type="text" value={formState.receipt_total_without_gst}
                        disabled={true}
                        className="block  rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-slate-200"
                      />
                    </div>
                    <div>
                      <label>Total Amount With GST</label>
                      <input type="text" value={formState.receipt_total_with_gst}
                        disabled={true}
                        className="block  border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-slate-200"
                      />
                    </div>
                  </div>
                  <div className="border-t-2 border-black">
                    <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 p-4">
                      <div>
                        <label className="mb-2 font-semibold text-gray-700">Extra Charge Name</label>
                        <Select
                          options={extraChargesDropdown}
                          value={extraChargesDropdown.find(option => option.value === formState.receipt_charge_name1) || null}
                          onChange={(e) => { setFormState((prev) => ({ ...prev, receipt_charge_name1: e.value })) }}
                          placeholder={"Select Charge..."}
                          styles={{
                            menuList: () => ({
                              overflowY: "scroll",
                              maxHeight: "200px",
                            }),
                            input: (baseStyles) => ({
                              ...baseStyles,
                              width: "180px"
                            }),
                            placeholder: (baseStyles) => (
                              {
                                ...baseStyles,
                              }
                            ),
                          }}
                        />
                      </div>

                      <div>
                        <label className="mb-2 font-semibold text-gray-700">Charge Amount</label>
                        <input
                          type="number"
                          name="receipt_charge_amount1"
                          value={formState.receipt_charge_amount1}
                          onChange={(e) => { setFormState((prev) => ({ ...prev, receipt_charge_amount1: e.target.value })) }}
                          className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                          placeholder="Quantity"
                        />
                      </div>

                      <div>
                        <label className="mb-2 font-semibold text-gray-700">GST %</label>
                        <Select
                          options={extraGstOptions}
                          // defaultInputValue={'0'}
                          value={extraGstOptions.find(option => option.value === formState.receipt_charge_gst1)}
                          onChange={(selectedOption) => { setFormState((prev) => ({ ...prev, receipt_charge_gst1: selectedOption.value })) }}
                          styles={{
                            menuList: () => ({
                              overflowY: "scroll",
                              maxHeight: "200px",
                            }),
                            input: (baseStyles) => ({
                              ...baseStyles,
                              width: "180px"
                            }),
                            placeholder: (baseStyles) => (
                              {
                                ...baseStyles,
                              }
                            ),
                          }}
                        />
                      </div>

                      <div>
                        <label className="mb-2 font-semibold text-gray-700">Charge With GST</label>
                        <input
                          type="number"
                          name="receipt_total_with_charge1"
                          disabled={true}
                          value={formState.receipt_total_with_charge1}
                          // onChange={(e) => { setFormState((prev) => ({ ...prev, total_amount: e.target.value })) }}
                          className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-slate-200"
                          placeholder="Quantity"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 p-4">
                      <div>
                        <label className="mb-2 font-semibold text-gray-700">Extra Charge Name</label>
                        <Select
                          options={extraChargesDropdown}
                          value={extraChargesDropdown.find(option => option.value === formState.receipt_charge_name2) || null}
                          onChange={(e) => { setFormState((prev) => ({ ...prev, receipt_charge_name2: e.value })) }}
                          placeholder={"Select Charge..."}
                          styles={{
                            menuList: () => ({
                              overflowY: "scroll",
                              maxHeight: "200px",
                            }),
                            input: (baseStyles) => ({
                              ...baseStyles,
                              width: "180px"
                            }),
                            placeholder: (baseStyles) => (
                              {
                                ...baseStyles,
                              }
                            ),
                          }}
                        />
                      </div>

                      <div>
                        <label className="mb-2 font-semibold text-gray-700">Charge Amount</label>
                        <input
                          type="number"
                          name="receipt_charge_amount2"
                          value={formState.receipt_charge_amount2}
                          onChange={(e) => { setFormState((prev) => ({ ...prev, receipt_charge_amount2: e.target.value })) }}
                          className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                          placeholder="Quantity"
                        />
                      </div>

                      <div>
                        <label className="mb-2 font-semibold text-gray-700">GST %</label>
                        <Select
                          options={extraGstOptions}
                          // defaultInputValue={'0'}
                          value={extraGstOptions.find(option => option.value === formState.receipt_charge_gst2)}
                          onChange={(selectedOption) => { setFormState((prev) => ({ ...prev, receipt_charge_gst2: selectedOption.value })) }}
                          styles={{
                            menuList: () => ({
                              overflowY: "scroll",
                              maxHeight: "200px",
                            }),
                            input: (baseStyles) => ({
                              ...baseStyles,
                              width: "180px"
                            }),
                            placeholder: (baseStyles) => (
                              {
                                ...baseStyles,
                              }
                            ),
                          }}
                        />
                      </div>

                      <div>
                        <label className="mb-2 font-semibold text-gray-700">Charge With GST</label>
                        <input
                          type="number"
                          name="receipt_total_with_charge2"
                          disabled={true}
                          value={formState.receipt_total_with_charge2}
                          // onChange={(e) => { setFormState((prev) => ({ ...prev, total_amount: e.target.value })) }}
                          className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-slate-200"
                          placeholder="Quantity"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="mb-2 font-semibold text-gray-700">Total Amount</label>
                      <input
                        type="number"
                        name="receipt_total_amount"
                        disabled={true}
                        value={formState.receipt_total_amount}
                        // onChange={(e) => { setFormState((prev) => ({ ...prev, total_amount: e.target.value })) }}
                        className="block w-full rounded border-0 py-1.5 pl-7 pr-10  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-slate-200"
                        placeholder="Total Amount"
                      />
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>

        <div className="w-full min-h-[500px] px-6 py-4 my-3 rounded-md shadow bg-[#fdfdfd]">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6  py-4 border-b-0">
            <div className="flex flex-col">
              <label className="mb-2 font-semibold text-gray-700">
                Terms of Payment <span className="text-red-500">*</span>
              </label>
              <input
                disabled={true}
                type="text"
                name="terms_of_payment"
                value={formState.terms_of_payment}
                // onChange={handleChange}
                className="block w-full rounded border-0 py-1.5 pl-7 pr-10 bg-slate-200  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                placeholder="Terms of Payment"
              />
            </div>
            <div className="flex flex-col">
              <label className="mb-2 font-semibold text-gray-700">
                Other References <span className="text-red-500">*</span>
              </label>
              <input
                disabled={true}
                type="text"
                name="other_references"
                value={formState.other_references}
                // onChange={handleChange}
                className="block w-full rounded border-0 py-1.5 pl-7 pr-10 bg-slate-200  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                placeholder="Other References"
              />
            </div>
            <div className="flex flex-col">
              <label className="mb-2 font-semibold text-gray-700">
                Terms of Delivery <span className="text-red-500">*</span>
              </label>
              <textarea
                disabled={true}
                type="text"
                name="terms_of_delivery"
                value={formState.terms_of_delivery}
                // onChange={handleChange}
                className="block w-full rounded border-0 py-1.5 pl-7 pr-10 bg-slate-200  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
                placeholder="Terms of Delivery"
              ></textarea>
            </div>
          </div>

          {/* form title part */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6  py-4 border-b-0">
            <div className="flex flex-col font-bold text-lg text-center">
              Receipt Details
            </div>
            <div className="flex flex-col font-bold text-lg text-center">
              Other Details
            </div>
          </div>

          {/* Receipt Detail Section */}
          <ReceiptFormOtherDetail formState={formState} setFormState={setFormState} />

          {/* Form Images Section */}
          <ReceiptFormImages formState={formState} setFormState={setFormState} receiptImgUrl={receiptImgUrl} />

          {/* Narration Section */}
          <div className="flex flex-col mt-6">
            <label className="mb-2 font-semibold text-gray-700">
              Narration
            </label>
            <textarea
              name="receipt_narration"
              value={formState.receipt_narration}
              onChange={(e) => { setFormState((prev) => ({ ...prev, receipt_narration: e.target.value })) }}
              className="block w-full rounded border-0 py-1.5 pl-7 pr-10   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset   sm:text-sm sm:leading-6"
              placeholder="Narration"
              rows="4"
            />
          </div>

          {/* Submit Button Section */}
          <div className="flex justify-end mt-6">
            <button
              type="button"
              className="px-6 py-2 mx-1 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
              onClick={submitAlertMsg}
            >
              {(receiptId && isNaN(receiptId)) ? 'Update' : 'Submit'}
            </button>
            {!(receiptId && isNaN(receiptId)) &&
              <button
                type="reset"
                className="px-6 py-2 mx-1 bg-red-600 text-white rounded-md hover:bg-red-700 transition"
                onClick={handleResetForm}
              >
                Reset
              </button>
            }
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReceiptNoteFrom;
