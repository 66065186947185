import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from './Axios/api';
import Select from 'react-select';
import { Trash2 } from 'lucide-react';

const PurchaseEdit = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({});
    const [purchaseDetails, setPurchaseDetails] = useState([]);
    const [parties, setParties] = useState([]);
    const [brands, setBrands] = useState([]);


    const getDetails = async () => {
        try {
            const res = await api.get(`/purchase/return-indent-item-detail/${id}`);
            console.log(res.data[0]);
            setFormData(res.data[0] || {});
            setPurchaseDetails(res.data[0].purchase_info)
        } catch (error) {
            console.log(error)
        }
    }


    const getParty = async () => {
        try {
            const res = await api.get(`vendor/get-name`)
            const data = res.data;

            const newArr = data.map((party, index) => {
                let val = party._id;
                return { label: party.vendor_name, value: val }
            })
            setParties(newArr)
        } catch (error) {
            console.log(error.response.data)
        }
    }





    // Handle input changes
    const handleChangePurchase = (index, field, value) => {
        const updatedDetails = [...purchaseDetails];

        updatedDetails[index] = {
            ...updatedDetails[index],
            [field]: value,
        };

        console.log(updatedDetails)
        setPurchaseDetails(updatedDetails);
    };

    console.log(purchaseDetails)


    const getBrands = async () => {
        try {
            const res = await api.get(`brand/get`);
            const data = res.data;

            const newArr = data.map((brand, index) => {
                let val = brand._id
                return { label: brand.brand_name, value: val }
            });
            setBrands(newArr);
        } catch (error) {
            console.log(error.response.data);
        }
    }

    function handleChange(item, label) {
        console.log(item)
    }

    useEffect(() => {
        getDetails();
        getParty();
        getBrands();
    }, [id])



    return (
        <div className=" w-[90%] mt-5 mx-auto p-4 bg-gray-50">
            {/* Top Section with Indent By, Branch, Date, and Remarks */}
            <div className='w-full justify-between items-center flex flex-wrap' >
                <div className='flex flex-col items-start justify-center p-3'>
                    <label htmlFor="itemName">Item Group</label>
                    <Select
                        placeholder={formData?.item_group_name}
                        value={formData?.item_group}

                        // options={itemGroups}
                        // onChange={(e) => setItems((prev) => ({ ...prev[]["item_group"], item_group: e.value }))}
                        // onChange={(e) => {
                        //     handleChange("item_group", e.label);
                        //     handleChange("item_group_id", e.value)
                        //     setItemGroupId(e.value)
                        // }}
                        styles={{
                            menuList: () => ({
                                overflowY: "scroll",
                                maxHeight: "200px",
                            }),
                            input: (baseStyles) => ({
                                ...baseStyles,
                                width: "180px"
                            }),
                            placeholder: (baseStyles) => (
                                {
                                    ...baseStyles,
                                    color: "black"
                                }
                            )
                        }}
                    />
                </div>
                <div className='flex flex-col items-start justify-center p-3'>
                    <label htmlFor="itemName">Item Name</label>
                    <Select
                        // options={dropItem}

                        placeholder={formData?.item_title?.length > 15 ? `${formData?.item_title?.slice(0, 20)}....` : formData?.item_title}
                        value={formData?.item_title}
                        onChange={(e) => {
                            handleChange("item_title", e.label)
                            handleChange("item_name", e.value)
                        }}
                        styles={{
                            menuList: () => ({
                                overflowY: "scroll",
                                maxHeight: "200px",
                            }),
                            input: (baseStyles) => ({
                                ...baseStyles,
                                width: "180px"
                            }),
                            placeholder: (baseStyles) => (
                                {
                                    ...baseStyles,
                                    color: "black"
                                }
                            )
                        }}
                    />
                </div>
                <div className='flex flex-col items-start justify-center p-3'>
                    <label htmlFor="currStock">Curr Stock</label>
                    <input
                        type="number"
                        className="block rounded outline-none border-1 py-1.5 pl-2  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                        value={formData?.current_stock}
                        onChange={(e) => handleChange('current_stock', e.target.value)}
                    />
                </div>
                <div className='flex flex-col items-start justify-center p-3'>
                    <label htmlFor="reqQty">Req Qty</label>
                    <input
                        type="number"
                        className="block rounded outline-none border-1 py-1.5 pl-2  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                        value={formData?.required_qty}
                        onChange={(e) => handleChange('required_qty', e.target.value)}
                    />
                </div>
                <div className='flex flex-col items-start justify-center p-3'>
                    <label htmlFor="placeOfUse">Place of Use</label>
                    <input
                        type="text"
                        className="block rounded outline-none border-1 py-1.5 pl-2  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                        value={formData?.place_of_use}
                        onChange={(e) => handleChange('place_of_use', e.target.value)}
                    />
                </div>
                <div className='flex flex-col items-start justify-center p-3'>
                    <label htmlFor="remark">Remark</label>
                    <input
                        type="text"
                        className="block rounded outline-none border-1 py-1.5 pl-2  text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 sm:text-sm sm:leading-6"
                        value={formData?.remark}
                        onChange={(e) => handleChange('remark', e.target.value)}
                    />
                </div>
                <div className='flex justify-center items-center'>
                    <button
                        className='p-2 m-2 bg-[#7E8EF1] text-white rounded'
                    // onClick={() => handleSave(item)}
                    >
                        Save
                    </button>


                </div>
                <hr className='underline w-full ' />
            </div>

            {
                purchaseDetails &&
                purchaseDetails?.map((purchaseDetails, index) => {
                    return (
                        <div className="p-6 mt-4 bg-white rounded shadow-lg">
                            <h2 className="text-lg font-semibold mb-4">Purchase Details</h2>
                            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">

                                <div>
                                    <label className="block mb-2 text-sm font-medium">Brand</label>
                                    <Select
                                        options={brands}
                                        value={{ value: purchaseDetails?.brand, label: purchaseDetails?.brand_name }}
                                        onChange={(selectedOption) => {
                                            console.log(selectedOption.value)
                                            handleChangePurchase(index, 'brand', selectedOption.value)
                                            // handleChangePurchase(index, 'brand_name', selectedOption.label)
                                        }}
                                    />
                                </div>

                                {/* GST */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium">GST</label>
                                    <input
                                        type="text"
                                        className="border w-full p-2"
                                        value={purchaseDetails?.gst}
                                        onChange={(e) => handleChangePurchase(index, 'gst', e.target.value)}
                                        placeholder="GST"
                                    />
                                </div>

                                {/* Split Quantity */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium">Split Quantity</label>
                                    <input
                                        type="number"
                                        className="border w-full p-2"
                                        value={purchaseDetails?.splitQuantity}
                                        onChange={(e) => handleChangePurchase(index, 'splitQuantity', e.target.value)}
                                        placeholder="Split Quantity"
                                    />
                                </div>

                                {/* Discount */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium">Discount</label>
                                    <input
                                        type="number"
                                        className="border w-full p-2"
                                        value={purchaseDetails?.discount}
                                        onChange={(e) => handleChangePurchase(index, 'discount', e.target.value)}
                                        placeholder="Discount"
                                    />
                                </div>

                                {/* Select Party */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium">Select Party</label>
                                    <Select
                                        options={parties}
                                        value={{ value: purchaseDetails?.party_name, label: purchaseDetails?.party_name }}
                                        onChange={(selectedOption) => {
                                            console.log(selectedOption.value)
                                            handleChangePurchase(index, 'party', selectedOption.value)
                                            handleChangePurchase(index, 'party_name', selectedOption.label)

                                        }}
                                    />
                                </div>
                                {/* Rate */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium">Rate</label>
                                    <input
                                        type="number"
                                        className="border w-full p-2"
                                        value={purchaseDetails?.rate}
                                        onChange={(e) => handleChangePurchase(index, 'rate', e.target.value)}
                                        placeholder="Rate"
                                    />
                                </div>

                                {/* Grand Total (Without GST) */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium">Grand Total (Without GST)</label>
                                    <input
                                        type="number"
                                        className="border w-full p-2"
                                        value={purchaseDetails?.total_without_gst}
                                        onChange={(e) => handleChangePurchase(index, 'grandTotalWithoutGST', e.target.value)}
                                        placeholder="Without GST"
                                    />
                                </div>

                                {/* Grand Total (With GST) */}
                                <div>
                                    <label className="block mb-2 text-sm font-medium">Grand Total (With GST)</label>
                                    <input
                                        type="number"
                                        className="border w-full p-2"
                                        value={purchaseDetails?.total_with_gst}
                                        onChange={(e) => (index, 'grandTotalWithGST', e.target.value)}
                                        placeholder="With GST"
                                    />
                                </div>
                            </div>

                            {/* Save Button */}
                            <div className="flex justify-end mt-4">
                                <button className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                            </div>
                        </div>
                    )
                })
            }

        </div>
    );
};


export default PurchaseEdit