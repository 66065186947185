// AddItems.js
import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import api from "../Axios/api";
import ItemForm from "./AddEditItemForm";

const AddItems = () => {
    const [rowCount, setRowCount] = useState(0);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [factoryLocation, setFactoryLocation] = useState([]);
    const [brandArray, setBrandArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);

    const [editId, setEditId] = useState(null);
    const [inputs, setInputs] = useState({
        item_title: "",
        item_category: "",
        item_brand: "",
        unit: "",
        gst: "",
        factory: "",
    });

    const [append, setAppend] = useState({
        category: "",
        brand: "",
        factory: "",
    });

    const [itemsData, setItemsData] = useState([]);

    const [extraCharges, setExtraCharges] = useState([]);

    const [ledgers, setLedgers] = useState([]);
    const [ledgerRowCount, setLedgerRowCount] = useState(0);
    const [ledgerPagination, setLedgerPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const columns = useMemo(
        () => [
            {
                accessorKey: "sn",
                header: "SN",
                size: 50,
                Cell: ({ row }) => row.index + 1,
            },
            { accessorKey: "item_title", header: "Title" },
            { accessorKey: "item_cat_name", header: "Category" },
            { accessorKey: "item_brand_name", header: "Brand" },
            { accessorKey: "item_unit", header: "Unit" },
            { accessorKey: "item_branch_name", header: "Fac" },
            {
                accessorKey: "action",
                header: "Action",
                Cell: ({ row }) => (
                    <div className="flex space-x-2">
                        <button
                            className="text-blue-600 hover:underline"
                            onClick={() => handleEdit(row)}
                        >
                            Edit
                        </button>
                        <button
                            className="text-red-600 hover:underline"
                            onClick={() => handleDelete(row)}
                        >
                            Delete
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const extraChargesColumns = [
        { accessorKey: "charge_name", header: "Charge Name" },
        {
            accessorKey: "charge_fac_id",
            header: "Factory",
            Cell: ({ cell }) => {
                const factoryMapping = {
                    "666c0f5ebeca5ab2c65b2c85": "POLYBOND INSULATION",
                    "666c0f5ebeca5ab2c65b2c86": "POLYBOND PROJECTS",
                };
                return factoryMapping[cell.getValue()] || "POLYBOND ROCKFIBRE";
            },
        },
    ];

    const ledgersColumns = [
        { accessorKey: "purchase_ledger_name", header: "Ledger Name" },
        {
            accessorKey: "factory_details",
            header: "Factory",
            Cell: ({ row }) => row.original.factory_details?.fectory_loca_name || "",
        },
    ];

    const getItems = async () => {
        try {
            const { pageIndex, pageSize } = pagination;
            const response = await api.get(
                `purchase-item/get-item/?limit=${pageSize}&page=${pageIndex + 1}`
            );
            setItemsData(response.data.items || []);
            setRowCount(response.data.totalResults)
        } catch (error) {
            console.log(error);
        }
    };

    const preparePayload = ({ factory, item_category, item_brand, item_title, unit, gst }) => ({
        item_title,
        item_unit: unit,
        item_gst: gst,
        item_itemcat: item_category,
        item_brand,
        item_branch: factory,
    });

    const addItem = async (e) => {
        e.preventDefault();
        try {
            const payload = preparePayload(inputs);
            const response = await api.post(`/purchase-item/add-items`, payload);
            const newItem = response.data;
            newItem.item_branch_name = append.factory;
            newItem.item_brand_name = append.brand;
            newItem.item_cat_name = append.category;
            setItemsData((prevData) => [newItem, ...prevData]);
            resetHandler();
        } catch (error) {
            console.log(error);
        }
    };

    const editItem = async (e, id) => {
        e.preventDefault();
        const payload = preparePayload(inputs);
        try {
            const response = await api.put(`/purchase-item/update-items/${id}`, payload);
            resetHandler();
            getItems();
        } catch (error) {
            console.log(error);
        }
    };

    const handleEdit = (row) => {
        setEditId(row.original._id);
        setInputs({
            item_title: row.original.item_title,
            item_category: row.original.item_itemcat,
            item_brand: row.original.item_brand,
            unit: row.original.item_unit,
            gst: row.original.item_gst,
            factory: row.original.item_branch,
        });
    };

    const handleDelete = async (row) => {
        const id = row.original._id;
        try {
            const response = await api.delete(`/purchase-item/delete-items/${id}`);
            getItems();
        } catch (error) {
            console.log(error);
        }
    };

    const resetHandler = () => {
        setInputs({
            item_title: "",
            item_category: "",
            item_brand: "",
            unit: "",
            gst: "",
            factory: "",
        });
        setEditId(null);
    };

    const fetchInitialData = async () => {
        try {
            const [factoriesResponse, brandsResponse, categoriesResponse] = await Promise.all([
                api.get(`/factory-location/get-factory-location`),
                api.get(`/brand/get`),
                api.get(`/purchase-item-category/get-name`),
            ]);
            setFactoryLocation(factoriesResponse.data);
            setBrandArray(brandsResponse.data);
            setCategoryArray(categoriesResponse.data);
        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    };

    const getExtraChargesAndLedgers = async () => {
        try {
            const [extraChargesResponse, ledgersResponse] = await Promise.all([
                api.get(`/extra-charges/get`),
                api.get(`/purchase-ledger/get?page=${ledgerPagination.pageIndex + 1}&limit=${ledgerPagination.pageSize}`),
            ]);

            setExtraCharges(extraChargesResponse.data.extraCharges);
            setLedgers(ledgersResponse.data.ledgers);
            setLedgerRowCount(ledgersResponse.data.totalCount);
        }
        catch (error) {
            console.error("Error fetching extra charges and ledgers:", error);
        }
    }

    useEffect(() => {
        getItems();
        fetchInitialData();
    }, [pagination]);

    useEffect(() => {
        getExtraChargesAndLedgers();
    }, [ledgerPagination]);

    return (
        <div className="flex flex-col relative">
            <div className="flex flex-col lg:flex-row gap-6 p-6 h-[680px]">
                <div className="bg-white p-6 rounded-lg shadow-md lg:flex-grow overflow-y-scroll scrollbar-none">
                    <h2 className="text-xl font-semibold mb-4">All Item</h2>
                    <MaterialReactTable
                        columns={columns}
                        data={itemsData}
                        manualPagination
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10, 20, 50, 100],
                        }}
                        state={{
                            pagination,
                        }}
                        rowCount={rowCount}
                        onPaginationChange={setPagination}
                    />
                </div>
                <ItemForm
                    inputs={inputs}
                    setInputs={setInputs}
                    append={append}
                    setAppend={setAppend}
                    editId={editId}
                    factoryLocation={factoryLocation}
                    categoryArray={categoryArray}
                    brandArray={brandArray}
                    addItem={addItem}
                    editItem={editItem}
                    resetHandler={resetHandler}
                />
            </div>
            <div className="flex flex-row gap-6 p-6">
                {/* Extra Charges Table */}
                <div className="bg-white p-6 rounded-lg shadow-md w-[50%]">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-semibold">Extra Charges</h2>
                        <button className="bg-purple-500 text-white px-4 py-2 rounded-lg">
                            Add Extra Charge
                        </button>
                    </div>
                    <MaterialReactTable
                        columns={extraChargesColumns}
                        data={extraCharges}
                        enablePagination
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10, 20, 50],
                        }}
                    />
                </div>

                {/* Purchase Ledger Table */}
                <div className="bg-white p-6 rounded-lg shadow-md w-[50%]">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-semibold">Purchase Ledger</h2>
                        <button className="bg-purple-500 text-white px-4 py-2 rounded-lg">Add Ledger</button>
                    </div>
                    <MaterialReactTable
                        columns={ledgersColumns}
                        data={ledgers}
                        manualPagination
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10, 20, 50, 100],
                        }}
                        state={{ pagination: ledgerPagination }}
                        rowCount={ledgerRowCount}
                        onPaginationChange={setLedgerPagination}
                    />
                </div>
            </div>
        </div>
    );
};

export default AddItems;