import { Eye, EyeOff, UserRound } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import api from '../Axios/api';
import Cookies from "js-cookie";
import { loginFailure, loginSuccess } from '../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
    const currentUser = useSelector((state) => state.user.staff_name);
    const token = Cookies.get('access_token');
    const navigation = useNavigate();
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [eyeToggle, setEyeToggle] = useState('password');
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        // Handle form submission
        try {
            const data = await api.post(`/staff/login`, { userId, password })
            const user = data.data;

            dispatch(loginSuccess({
                _id: user._id,
                staff_name: user.staff_name,
                staff_role: user.staff_role,
                staff_pic: user.staff_pic,
                staff_FacLoc: user.staff_FacLoc,
            }))

            Cookies.set('access_token', user.token, { expires: 1, sameSite: 'None', secure: true });
            // navigation("/dashboard")
            window.location = "/dashboard";
        } catch (error) {
            console.log(error)
            setLoginError(error.response.data.message);
            setLoading(false);
            dispatch(loginFailure())
        }
    };

    useEffect(() => {
        currentUser && navigation("/");
    })

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="flex bg-white rounded-lg shadow-lg w-full max-w-4xl">
                {/* Left Side - Illustration */}
                <div className="hidden md:flex w-1/2 bg-cover bg-center rounded-l-lg" style={{ backgroundImage: 'url("login-page.png")' }}>
                    {/* You can replace the above URL with the actual image URL */}
                </div>

                {/* Right Side - Form */}
                <div className="w-full md:w-1/2 p-8">
                    <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
                    {loginError && <p className="text-center text-[red]">{loginError}</p>}
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700">Email</label>
                            <div className="flex items-center border border-gray-300 rounded-lg p-2">
                                <input
                                    type="text"
                                    value={userId}
                                    onChange={(e) => setUserId(e.target.value)}
                                    className="w-full focus:outline-none"
                                    placeholder="username"
                                    required
                                />
                                <span className="text-gray-400 material-icons"><UserRound /></span>
                            </div>
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-700">Password</label>
                            <div className="flex items-center border border-gray-300 rounded-lg p-2">
                                <input
                                    type={eyeToggle}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="w-full focus:outline-none"
                                    placeholder="••••••••"
                                    required
                                />
                                <span className="text-gray-400 material-icons" onClick={() => setEyeToggle(eyeToggle === 'text' ? 'password' : 'text')}>
                                    {eyeToggle === 'text' ? <Eye /> : <EyeOff />}
                                </span>
                            </div>
                        </div>
                        <button
                            type="submit"
                            disabled={loading}
                            className={`w-full py-3 px-4 ${loading ? 'bg-[#ffa6a9]' : 'bg-[#f75257]'} hover:bg-[#EC3237] text-white font-semibold rounded-lg transition duration-300`}
                        >
                            {loading ? 'Loading' : 'Login'}
                        </button>
                        <div className="mt-4 text-center">
                            <Link href="#" className="text-sm text-[#EC3237] hover:underline">Forgot Password?</Link>
                        </div>
                        <div className="mt-4 text-center">
                            <span className="text-sm text-gray-700">Don't have an account?</span>
                            <Link href="#" className="text-sm text-[#EC3237] hover:underline ml-1">Sign Up</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
