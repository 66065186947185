import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode"; // To decode JWT token
import { logout } from './redux/userSlice';

const AuthRouter = () => {
    const dispatch = useDispatch();
    const token = Cookies.get('access_token');
    const currentUser = useSelector((state) => state.user.staff_name);

    // Function to check if the token is expired
    const isTokenExpired = (token) => {
        if (!token) return true;
        const { exp } = jwtDecode(token);
        return Date.now() >= exp * 1000;
    }

    useEffect(() => {
        // Check token expiration on component mount
        if (isTokenExpired(token)) {
            dispatch(logout());
            Cookies.remove('access_token');
        }

        // Set up an interval to periodically check token expiration
        const intervalId = setInterval(() => {
            if (isTokenExpired(token)) {
                dispatch(logout());
                Cookies.remove('access_token');
            }
        }, 60000); // Check every minute

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [token, dispatch]);

    return (
        currentUser ? <Outlet /> : <Navigate to="/login" />
    );
}

export default AuthRouter;
