import React, { useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Eye, Pencil } from 'lucide-react';
import api from '../Axios/api';

const DataTable = ({ data, setData }) => {

    const [purchaseStaffs, setPurchaseStaffs] = useState([]);
    const [editingRowId, setEditingRowId] = useState(null);

    const getPurchaseStaffs = async () => {
        try {
            const res = await api.get('/staff/get');
            console.log("Purchaser", res.data);
            setPurchaseStaffs(res.data);
        } catch (error) {
            console.error('Error fetching purchase staffs:', error);
        }
    };

    const handlePurchaserChange = async (indentId, purchaserId) => {
        try {
            const res = await api.patch(`/indent/update-indent/${indentId}`, {
                indent_purchaser: purchaserId,
            });

            console.log('Updated Indent:', res.data);
            setData(prevData =>
                prevData.map(indent =>
                    indent._id === indentId ? { ...indent, indent_purchaser: purchaserId } : indent
                )
            );
            setEditingRowId(null); // Exit edit mode after selection
        } catch (error) {
            console.error('Error updating purchaser:', error);
            alert('Failed to update purchaser.');
        }
    };

    useEffect(() => {
        getPurchaseStaffs();
    }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'indent_date',
                header: 'Indent Date',
                size: 150,
                Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
            },
            {
                accessorKey: 'indent_number',
                header: 'Indent Number',
                size: 150,
            },
            {
                accessorKey: 'indent_branch_name',
                header: 'Branch Name',
                size: 200,
                Cell: ({ cell }) => (
                    <div className="max-h-10 overflow-y-auto">
                        {cell.getValue().split(' ')[1]}
                    </div>
                ),
            },
            {
                accessorKey: 'items',
                header: 'Item Titles',
                size: 300,
                Cell: ({ cell }) => (
                    <div className="max-h-10 overflow-y-auto">
                        {cell.getValue().map(item => (
                            <div key={item._id}>{item.item_title}</div>
                        ))}
                    </div>
                ),
            },
            {
                accessorKey: 'indent_remark',
                header: 'Remark',
                size: 200,
            },
            {
                accessorKey: 'indent_by_name',
                header: 'Indent By',
                size: 150,
            },
            {
                accessorKey: 'purchaser',
                header: 'Purchaser',
                size: 150,
                Cell: ({ row }) => {
                    const isEditing = editingRowId === row.original._id;

                    return (
                        <div className="flex items-center gap-2">
                            {isEditing ? (
                                <select
                                    className="border p-1 rounded"
                                    value={row.original.indent_purchaser || ''}
                                    onChange={(e) => handlePurchaserChange(row.original._id, e.target.value)}
                                    onBlur={() => setEditingRowId(null)} // Close dropdown when it loses focus
                                    autoFocus
                                >
                                    <option value="">Select Purchaser</option>
                                    {purchaseStaffs.map((staff) => (
                                        <option key={staff._id} value={staff._id}>
                                            {staff.staff_name}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <>
                                    <span>{purchaseStaffs.find(staff => staff._id === row.original.indent_purchaser)?.staff_name || 'Not Assigned'}</span>
                                    <button onClick={() => setEditingRowId(row.original._id)}>
                                        <Pencil size={18} className="cursor-pointer" />
                                    </button>
                                </>
                            )}
                        </div>
                    );
                }
            },
            {
                accessorKey: '_id',
                header: 'Action',
                size: 200,
                Cell: ({ cell }) => (
                    <div className='flex items-center gap-3'>
                        <Link to={`/details/${cell.getValue()}`}>
                            <button><Eye size={20} /></button>
                        </Link>
                        <Link to={`/edit/${cell.getValue()}`}>
                            <button><Pencil size={18} /></button>
                        </Link>
                    </div>
                ),
            },
            {
                accessorKey: 'indent_status',
                header: 'Status',
                size: 150,
            },
        ],
        [purchaseStaffs, editingRowId]
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowSelection: false,
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        renderTopToolbarCustomActions: () => (
            <div className='flex gap-2 px-1 mt-1'>
                <button className='p-1 bg-[#EEEDEB] rounded-t-lg shadow-sm' onClick={exportToExcel}>
                    <span className="text-sm">Export Excel</span>
                </button>
                <button className='p-1 bg-[#EEEDEB] shadow-sm rounded-t-lg text-sm' onClick={exportToPdf} >Export PDF</button>
            </div>
        ),
    });

    const exportToExcel = () => {
        const excelData = data.map((row, index) => ({
            'S.No.': index + 1,
            'Indent Date': new Date(row.indent_date).toLocaleDateString(),
            'Branch Name': row.indent_branch_name,
            'Item Titles': row.items.map(item => item.item_title).join(', '),
            'Status': row.indent_status,
            'Remark': row.indent_remark,
            'Indent By': row.indent_by_name,
            'Action': '-->',
        }));

        // Convert JSON to worksheet
        const worksheet = XLSX.utils.json_to_sheet(excelData);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data');

        // Export the workbook to an Excel file
        XLSX.writeFile(workbook, 'indent.xlsx');
    };

    const exportToPdf = () => {
        const doc = new jsPDF();
        const tableColumn = ["SN.", "Branch Name", "Indent By", "Indent Date", "Status", "Remark", "Item Titles"];
        const tableRows = [];

        let sn = 1;
        data.forEach(row => {
            const rowData = [
                sn++,
                row.indent_branch_name,
                row.indent_by_name,
                new Date(row.indent_date).toLocaleDateString(),
                row.indent_status,
                row.indent_remark,
                row.items.map(item => item.item_title).join(', '),
            ];
            tableRows.push(rowData);
        });

        const pageWidth = doc.internal.pageSize.getWidth();
        const textWidth = doc.getStringUnitWidth("Indent Report") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const textX = (pageWidth - textWidth) / 2;
        // Add a centered header
        doc.text("Indent Report", textX, 15);
        // Add the table
        doc.autoTable({
            startY: 20,
            head: [tableColumn],
            body: tableRows,
        });
        doc.save("polybond_indent.pdf");
    };

    return (
        <div>
            <MaterialReactTable table={table} />
        </div>
    );
};

export default DataTable;
