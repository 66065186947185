import React, { useEffect, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import api from "../Axios/api";

const MaterialInOutReport = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    // Sample data for the table
    const [data, setData] = useState([]);

    // Table columns
    const columns = [
        {
            accessorKey: 'id',
            header: 'S.No',
        },
        {
            accessorKey: 'date',
            header: 'Date',
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'location',
            header: 'Location',
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                <span
                    className={`px-2 py-1 rounded ${cell.getValue() === 'Success' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
                        }`}
                >
                    {cell.getValue()}
                </span>
            ),
        },
        {
            accessorKey: 'action',
            header: 'Action',
            Cell: ({ row }) => (
                <button
                    className="text-blue-500 underline hover:text-blue-700"
                    onClick={() => handleAction(row)}
                >
                    {row.original.status === 'Success' ? 'View' : 'Edit'}
                </button>
            ),
        },
    ];


    // Handle action button
    const handleAction = (row) => {
        navigate(`/material-in-out-view/${row.original.action}`, {
            state: { data: row.original, status: row.original.status },
        });
    };

    const fetchData = async () => {
        try {
            const res = await api.get(`/material-in-out/get`);
            console.log("res", res);
            const transformedData = res.data.map((item, index) => ({
                id: index + 1, // Add serial number
                date: new Date(item.entryDate).toISOString().split('T')[0],
                name: item.materialName,
                location: item.serviceLocation,
                status: item.repairedDate ? "Success" : "Pending",
                modelNumber: item.modelNumber,
                action: item._id, // Preserve `_id` for actions
                explainProblem: item.explainProblem,
                expectedRepairedDate: new Date(item.expectedRepairedDate).toISOString().split('T')[0],
                workDone: item.workDone,
                warrantyGuarantee: item.warrantyGuarantee,
                checkedByMachine: item.checkedByMachine,
                expenseAmount: item.expenseAmount,
                repairedDate: item.expectedRepairedDate
            }));
            setData(transformedData);
            console.log(transformedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className="p-6">
            <div className='flex flex-row items-center justify-between bg-white p-3 mb-3 rounded-lg'>
                <h1 className="text-2xl font-bold mb-0 text-gray-800">Material In/Out Report</h1>
                <button
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                    onClick={() => {
                        navigate('/material-in-out-form');
                    }}
                >
                    Add Form
                </button>
            </div>

            {/* Table */}
            <MaterialReactTable
                columns={columns}
                data={data}
                enablePagination
                enableBottomToolbar
                muiTableBodyRowProps={{
                    hover: true,
                }}
            />
        </div>
    );
};

export default MaterialInOutReport;