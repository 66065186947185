import { ChevronFirst, LogOut } from 'lucide-react';
import React, { createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../redux/userSlice';
import Cookies from "js-cookie";

const SideBarContext = createContext();
const Sidebar = ({ children }) => {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const staffName = useSelector((state) => state.user.staff_name);
    console.log("name:", staffName);
    const user = staffName ? staffName : undefined

    const logOut = () => {
        Cookies.remove("access_token");
        dispatch(logout());
    }

    return (
        <aside className='h-screen hidden sm:block sideNav ' >
            <nav className='h-screen flex flex-col border-r bg-white shadow-sm  overflow-y-scroll scrollbar-none '>
                <div className='p-4 pb-2 flex justify-between items-center'>
                    <img
                        src="https://www.polybond.co.in/polybond/assets/images/logo.png"
                        className={`overflow-hidden transition-all  ${expanded ? "w-28" : "w-0"}`}
                        alt=""
                    />
                    <button
                        onClick={() => setExpanded((cur) => !cur)}
                        className='p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100'
                    >
                        <ChevronFirst className={`transform transition-transform duration-300 ${expanded ? '' : 'rotate-180'}`} />
                    </button>
                </div>
                <SideBarContext.Provider value={{ expanded }}>
                    <ul className='flex-1 px-3 flex flex-col  gap-4 navList'>{children}</ul>
                </SideBarContext.Provider>
                <div className='border-t flex p-3'>

                    <button onClick={logOut} className="text-sm font-semibold p-1 px-2 border shadow transform transition duration-150 ease-in-out hover:scale-105 active:scale-95"><LogOut /></button>
                    <div
                        className={`flex justify-evenly items-center overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}
                    >
                        <div className='w-10 h-10 rounded-md bg-gray-200 flex justify-center items-center'>
                            <h2 className=' text-center '>{staffName ? staffName.split(" ").map(word => word[0]).join("").toUpperCase() : "NA"}</h2>
                        </div>
                        <div className='leading-1'>
                            <h4 className="font-semibold">{user}</h4> {/*name of the user*/}
                        </div>
                    </div>
                </div>
            </nav>
        </aside>
    );
};

export default Sidebar;

export function SidebarItem({ icon, text, active, alert, to }) {
    const { expanded } = useContext(SideBarContext);
    return (
        <Link to={to}
            className={`
                relative flex items-center py-1 px-3 my-1
                font-medium rounded-md cursor-pointer
                transition-colors group
                ${expanded ? active ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
                    : "hover:bg-indigo-50 text-gray-600" : active ? "bg-gradient-to-tr from-indigo-200 to-indigo-100"
                    : "hover:bg-indigo-50 text-gray-600"}
            `}
        >
            {icon}
            <span className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>{expanded ? text : ""}</span>
            {alert && (
                <div className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${expanded ? "" : "top-2"}`} />
            )}
            {!expanded && (
                <div
                    className={`
                        absolute z-10 left-full rounded-md px-2 py-1 ml-6 
                        bg-indigo-100 text-indigo-800 text-sm
                        invisible opacity-0 -translate-x-10 transition-all
                        group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
                    `}
                >
                    {text}
                </div>
            )}
        </Link>
    );
}
