import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import api from "../Axios/api";
import AddEditCategoryForm from "./AddEditCategoryForm"; // Import the new form component

const AddCategory = () => {
    const [categoryData, setCategoryData] = useState([]);
    const [editId, setEditId] = useState(null);
    const [inputs, setInputs] = useState({
        itemcat_title: "",
        itemcat_desc: "",
    });

    const getCategory = async () => {
        try {
            const response = await api.get(`/purchase-item-category/get`);
            setCategoryData(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const addCategory = async (e) => {
        try {
            e.preventDefault();
            const response = await api.post(`/purchase-item-category/add`, inputs);
            console.log(response.data);
            setCategoryData((prev) => [...prev, response.data]);
            resetHandler();
        } catch (error) {
            console.log(error);
        }
    };

    const editCategory = async (e, id) => {
        try {
            e.preventDefault();
            const response = await api.put(`/purchase-item-category/update/${id}`, inputs);
            console.log(response.data);
            setCategoryData((prev) => prev.map((item) => (item._id === id ? response.data : item)));
            resetHandler();
        } catch (error) {
            console.log(error);
        }
    };

    const deleteCategory = async (id) => {
        try {
            const response = await api.delete(`/purchase-item-category/delete/${id}`);
            console.log(response.data);
            setCategoryData((prev) => prev.filter((item) => item._id !== id));
        } catch (error) {
            console.log(error);
        }
    };

    const getOneCategory = async (id) => {
        try {
            const response = await api.get(`/purchase-item-category/get/${id}`);
            setInputs(response.data);
            setEditId(id);
        } catch (error) {
            console.log(error);
        }
    };

    const resetHandler = () => {
        setInputs({
            itemcat_title: "",
            itemcat_desc: "",
        });
        setEditId(null);
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "itemcat_title",
                header: "Title",
            },
            {
                accessorKey: "itemcat_desc",
                header: "Description",
            },
            {
                accessorKey: "itemcat_added_on",
                header: "Date",
                Cell: ({ cell }) => <div>{cell.getValue().split("T")[0]}</div>,
            },
            {
                accessorKey: "_id",
                header: "Actions",
                Cell: ({ cell }) => (
                    <div className="flex gap-2">
                        <button
                            variant="contained"
                            className="bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded-md text-sm"
                            onClick={() => getOneCategory(cell.getValue())}
                        >
                            Edit
                        </button>
                        <button
                            variant="contained"
                            className="bg-red-500 text-white hover:bg-red-600 px-2 py-1 rounded-md text-sm"
                            onClick={() => deleteCategory(cell.getValue())}
                        >
                            Delete
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    useEffect(() => {
        getCategory();
    }, []);

    return (
        <div className="flex flex-col lg:flex-row gap-6 p-6 max-h-[520px]">
            <div className="bg-white p-6 rounded-lg shadow-md lg:flex-grow overflow-y-scroll scrollbar-none">
                <h2 className="text-xl font-semibold mb-4">All Item Categories</h2>
                <MaterialReactTable
                    columns={columns}
                    data={categoryData}
                    muiTableProps={{
                        className: "w-full",
                    }}
                />
            </div>
            {/* Pass necessary props to the form */}
            <AddEditCategoryForm
                inputs={inputs}
                setInputs={setInputs}
                addCategory={addCategory}
                editCategory={editCategory}
                resetHandler={resetHandler}
                editId={editId}
            />
        </div>
    );
};

export default AddCategory;
