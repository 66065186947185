import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import BackBtn from './common/BackBtn';
const HeadTag = ({ tagName, to, type, buttonName, set, show }) => {

    const navigate = useNavigate();

    const hideShowModal = () => {
        set(!show)
    }

    return (
        <div className='bg-[#fdfdfd] border mt-4 p-2 flex w-11/12 justify-between items-center rounded-md  shadow'>
            <div className=' flex rounded-md  items-center'>
                <h2 className='font-bold text-violet-500'>{tagName}</h2>
            </div>
            <div className="">
                {
                    type === 'new' ?
                        <Link to={'/indentForm'} className='bg-violet-600 hover:bg-violet-700 transition-colors rounded-md shadow-sm px-2 py-1 hover:ease-in text-white text-sm flex items-center'>{buttonName}</Link>
                        : <div className="flex flex-row gap-2 flex-wrap">
                            <div
                                onClick={() => navigate('/add-party')}
                                className='bg-violet-600 hover:bg-violet-700 transition-colors cursor-pointer rounded-md shadow-sm px-2 py-1 hover:ease-in text-white text-sm flex items-center'
                            >
                                Add Party
                            </div>
                            {
                                show
                                    ? <div onClick={() => hideShowModal()} className='bg-red-600 hover:bg-red-700 hover:ease-in transition-colors cursor-pointer rounded-md shadow px-2 py-1 text-[#ffff] text-sm flex items-center'>Hide Modal</div>
                                    : <div onClick={() => hideShowModal()} className='bg-violet-600 hover:bg-violet-700 transition-colors cursor-pointer rounded-md shadow-sm px-2 py-1 hover:ease-in text-[#ffff] text-sm flex items-center text-center'>{buttonName}</div>
                            }
                            <BackBtn />
                        </div>
                }
            </div>
        </div>
    )
}

export default HeadTag