import React from "react";

const AddEditCategoryForm = ({ inputs, setInputs, addCategory, editCategory, resetHandler, editId }) => {
    return (
        <div className="bg-white p-6 rounded-lg shadow-md lg:w-1/3">
            <h2 className="text-xl font-semibold mb-4">{editId ? "Edit Category" : "Category Form"}</h2>
            <form
                className="space-y-4"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (editId) {
                        editCategory(e, editId);
                    } else {
                        addCategory(e);
                    }
                }}
            >
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Category Title*
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Category Title"
                        onChange={(e) => setInputs((prev) => ({ ...prev, itemcat_title: e.target.value }))}
                        value={inputs.itemcat_title}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Description
                    </label>
                    <textarea
                        rows={4}
                        placeholder="Enter Description"
                        onChange={(e) => setInputs((prev) => ({ ...prev, itemcat_desc: e.target.value }))}
                        value={inputs.itemcat_desc}
                        className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    ></textarea>
                </div>
                <div className="flex gap-4">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                    >
                        Submit
                    </button>
                    <button
                        type="reset"
                        className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                        onClick={resetHandler}
                    >
                        Reset
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddEditCategoryForm;