import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import api from "../Axios/api";
import moment from 'moment';
import Swal from "sweetalert2";
import { Trash2 } from "lucide-react";

function PurchaseEntry() {
  const [purchasePaymentList, setPurchasePaymentList] = useState([]);

  const getPurchasePaymentList = async () => {
    try {
      const response = await api.get("/purchase-payment/get");
      setPurchasePaymentList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "receipt_no",
        header: "Receipt No.",
        size: 150,
        Cell: ({ cell, row }) => (
          <div className="max-h-12 overflow-y-auto">
            <div className="font-bold">{cell.getValue()}</div>
            <code>{moment(row.original.entry_date).format('DD/MM/YYYY')}</code>
          </div>
        ),
      },
      {
        accessorKey: "invoice_number",
        header: "Invoice No.",
        size: 150,
        Cell: ({ cell, row }) => (
          <div className="max-h-12 overflow-y-auto">
            <div className="font-bold">{cell.getValue()}</div>
            <code>{moment(row.original.invoice_date).format('DD/MM/YYYY')}</code>
          </div>
        ),
      },
      {
        accessorKey: "vendor_name",
        header: "Party Name",
        size: 200,
      },
      {
        accessorKey: "grn_value",
        header: "GRN Value",
        size: 120,
        Cell: ({ cell, row }) => (
          <div className="max-h-12 overflow-y-auto">
            <div className="font-bold">₹{cell.getValue()}</div>
            <div className={`${row.original.dcr_type === 'credit' ? 'text-red-600' : row.original.dcr_type === 'debit' ? 'text-green-600' : 'text-gray-600'} font-bold`}>₹{row.original.dcr_amount}</div>
          </div>
        ),
      },
      {
        accessorKey: "total_amount",
        header: "Total Amount",
        size: 180,
        Cell: ({ cell }) => (
          <div>{formatAmount(cell.getValue())}</div>
        ),
      },
      {
        accessorKey: "po_number",
        header: "PO No.",
        size: 150,
        Cell: ({ cell }) => (
          <div className="max-h-10 overflow-y-auto">
            {cell.getValue().map(poNo => (
              <div key={poNo}>{poNo}</div>
            ))}
          </div>
        ),
      },
      {
        accessorKey: "remark",
        header: "Remark",
        size: 200,
      },
      {
        accessorKey: "_id",
        header: "Action",
        size: 100,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.status === "pending" ? <button
              type="button"
              className="text-white bg-gradient-to-r from-violet-500 via-violet-600 to-violet-700 hover:bg-gradient-to-br focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 text-center me-2 mb-2"
              onClick={() => { handleModalOpen(row) }}
            >
              Payment
            </button>
              : <button
                type="button"
                className="text-white bg-gradient-to-r from-green-500 via-green-600 to-green-700 hover:bg-gradient-to-br focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 text-center me-2 mb-2"
                onClick={() => { handleModalOpen(row) }}
              >
                Paid
              </button>}
            <button
              type="button"
              className="text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 text-center me-2 mb-2"
              onClick={() => { handleDelete(row.original._id) }}
            >
              <Trash2 size={20} />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: purchasePaymentList,
  });

  const handleDelete = (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          await api.delete(`/purchase-payment/delete/${id}`);
          Swal.fire({
            title: "Deleted!",
            text: "Your data has been deleted.",
            icon: "success"
          });
          getPurchasePaymentList()
        }
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Payment Failed!",
        text: "Failed to submit payment. Please try again later.",
        icon: "error",
      });
    }
  };

  // format number to INR Amount
  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
  }

  const handleModalOpen = (rowData) => {
    const prevAmount = rowData.original.payment_details.reduce((acc, item) => acc + Number(item.paid_amount), 0);
    Swal.fire({
      icon: "warning",
      title: rowData.original.status === "pending" ? "Are you sure?" : "Payment Details",
      html: `
        <div style="font-size: 20px; font-weight: bold;">
          Party: <span class="text-violet-600">${rowData.original.vendor_name}</span>
        </div>
        <div style="font-size: 20px; font-weight: bold;">
          <div>Payment: <span style="color:#296106">${formatAmount(rowData.original.total_amount)}</span></div>
          <div>Balance: <span style="color:red">${formatAmount(rowData.original.total_amount - prevAmount)}</span></div>
        </div>
        <table class="w-full mb-4 text-center border-collapse leading-8 text-sm">
          <thead>
            <tr style="border-bottom: 0.5px solid #ddd; color:#B5B7C0;">
              <th>SN</th>
              <th>Transactions</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
          ${rowData.original.payment_details.length === 0 ?
          '<tr class="border"><td colspan="4">Data Not Found!</td></tr>'
          : rowData.original.payment_details.map((item, index) => {
            return `<tr class="border-b">
                  <td>${index + 1}</td>
                  <td>${item.transaction_id}</td>
                  <td>${formatAmount(item.paid_amount)}</td>
                  <td>${moment(item.payment_date).format('DD/MM/YYYY')}</td>
                </tr>`;
          })
        }
          <tr>
            <td colspan="2" class="font-bold">Total Paid:</td>
            <td class="font-bold text-[#296106]">${formatAmount(prevAmount)}</td>
            <td></td>
          </tr>
          </tbody>
        </table>
        ${rowData.original.status === "pending" ? `<div class="flex justify-between">
          <input type="text" id="transaction_id" class="w-[30%] rounded border border-[#D5D7DA] py-1.5 px-3 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6" placeholder="Enter Transaction Id">
          <input type="number" id="amount" class="w-[30%] rounded border border-[#D5D7DA] py-1.5 px-3 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6" placeholder="Enter Amount">
          <input type="date" id="transactionDate" class="w-[30%] rounded border border-[#D5D7DA] py-1.5 px-3 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
        </div>` : ""}
      `,
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: rowData.original.status === "pending",
      confirmButtonText: rowData.original.status === "pending" ? "Submit" : "OK",
      preConfirm: () => {
        if (rowData.original.status === "pending") {
          const totalAmount = rowData.original.total_amount;
          const transaction_id = document.getElementById("transaction_id").value;
          const amount = document.getElementById("amount").value;
          const transactionDate = document.getElementById("transactionDate").value;
          const status = totalAmount === (Number(amount) + prevAmount) ? "paid" : "pending";

          if (!transaction_id || !amount || !transactionDate) {
            Swal.showValidationMessage("Please fill all fields.");
            return false;
          } else if (totalAmount < (Number(amount) + prevAmount)) {
            Swal.showValidationMessage("Payment amount should not exceed total amount.");
            return false;
          }

          // submit details to api using axios patch request
          api.patch(`/purchase-payment/update/${rowData.original._id}`,
            {
              transaction_id,
              paid_amount: Number(amount),
              payment_date: moment(transactionDate).format("YYYY-MM-DD"),
              status
            })
            .then((response) => {
              Swal.fire({
                title: "Payment Submitted!",
                text: `Transaction Amount: ₹${transaction_id}, Paid Amount: ₹${formatAmount(amount)}, Date: ${moment(transactionDate).format("YYYY-MM-DD")}`,
                icon: "success",
                timer: 5000
              });
              getPurchasePaymentList();
            })
            .catch((error) => {
              console.error(error);
              Swal.fire({
                title: "Payment Failed!",
                text: "Failed to submit payment. Please try again later.",
                icon: "error",
              });
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  };

  useEffect(() => {
    getPurchasePaymentList();
  }, []);

  return (
    <div className="flex-1 px-5 w-full justify-center items-center" >
      <div>
        <div className="bg-[#fdfdfd] border mt-4 p-2 flex w-full justify-between items-center rounded-md shadow">
          <div className="flex rounded-md items-center w-1/2">
            <h2 className="font-bold text-[#7286D3]">Purchase Entry</h2>
          </div>
        </div>
      </div>
      <div className="w-full min-h-[500px] my-2 rounded-md shadow bg-[#fdfdfd]">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
}

export default PurchaseEntry;