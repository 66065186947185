import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from "../Axios/api";

const MaterialForm = () => {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        entryDate: '',
        materialName: '',
        modelNumber: '',
        serviceLocation: '',
        explainProblem: '',
        expectedRepairedDate: '',
    });

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        const res = await api.post(`/material-in-out/add`, formData);
        console.log(res);
        navigate(`/material-in-out`);
    };

    return (
        <div>
            <div className="flex justify-between items-center w-[98%] py-3 px-5 bg-white shadow-lg rounded-lg my-5 ml-6">
                <h1 className="text-2xl font-bold text-gray-800">Material In/Out</h1>
                <button
                    className="bg-purple-500 text-white py-2 px-6 rounded-lg hover:bg-purple-600 focus:outline-none"
                    onClick={() => {
                        navigate('/material-in-out');
                    }}
                >
                    Back
                </button>
            </div>
            <div className="w-[98%] p-6 bg-white shadow-lg rounded-lg mt-2 ml-6">
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-x-6 gap-y-3">
                        {/* Entry Date */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">
                                Entry Date <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="date"
                                name="entryDate"
                                value={formData.entryDate}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            />
                        </div>

                        {/* Material Name */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">
                                Material Name <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                name="materialName"
                                value={formData.materialName}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Enter material name"
                            />
                        </div>

                        {/* Model Number */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">
                                Model Number <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                name="modelNumber"
                                value={formData.modelNumber}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Enter model number"
                            />
                        </div>

                        {/* Expected Repair Date */}
                        <div>
                            <label className="block text-base font-medium text-gray-800 mb-2">
                                Expected Repaired Date <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="date"
                                name="expectedRepairedDate"
                                value={formData.expectedRepairedDate}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                            />
                        </div>

                        {/* Service Location */}
                        <div className="col-span-2">
                            <label className="block text-base font-medium text-gray-800 mb-2">
                                Service Location <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                name="serviceLocation"
                                value={formData.serviceLocation}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Enter service location"
                            />
                        </div>

                        {/* Explain Problem */}
                        <div className="col-span-2">
                            <label className="block text-base font-medium text-gray-800 mb-2">
                                Explain Problem <span className="text-red-500">*</span>
                            </label>
                            <textarea
                                name="explainProblem"
                                value={formData.explainProblem}
                                onChange={handleChange}
                                rows="5"
                                className="w-full border rounded-lg px-4 py-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                placeholder="Describe the problem here..."
                            />
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className="mt-4">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 focus:outline-none"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MaterialForm;
