import React, { useRef } from "react";

const FormatPdf = () => {
  const componentRef = useRef();

  const handlePdf = () => {
    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print PDF</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                padding: 20px;
              }
              table {
                width: 100%;
                border-collapse: collapse;
              }
              th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
            </style>
          </head>
          <body>
            ${componentRef.current.innerHTML}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }
  };

  return (
    <div className="p-6 font-sans">
      <button
        onClick={handlePdf}
        className="mb-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
      >
        Print PDF
      </button>

      <div ref={componentRef} className="p-6 font-sans">
        <h2 className="text-center text-xl font-bold mb-4">MATERIAL IN</h2>

        {/* Header Table */}
        <table className="w-full border-collapse mb-4 text-sm">
        <tbody>
          <tr>
            <td className="font-bold border px-3 py-2">Consignee (Ship to)</td>
            <td colSpan="2" className="border px-3 py-2">
              Order No.: <span className="font-bold">GRNTEST1, GRNTEST4</span>
            </td>
            <td className="border px-3 py-2">
              GRN No.: <span className="font-bold">TEST1</span>
            </td>
          </tr>
          <tr>
            <td className="font-bold border px-3 py-2">POLYBOND INSULATION PVT LTD</td>
            <td colSpan="2" className="border px-3 py-2">
              PO Date: <span className="font-bold">29-03-2025</span>
            </td>
            <td className="border px-3 py-2">
              GRN Date: <span className="font-bold">24-03-2025</span>
            </td>
          </tr>
          <tr>
            <td className="border px-3 py-2">State Name: Chhattisgarh, Code: 22</td>
            <td colSpan="2" className="border px-3 py-2">
              Challan No.: <span className="font-bold">1</span>
            </td>
            <td className="border px-3 py-2">
              Challan Date: <span className="font-bold">24-03-2025</span>
            </td>
          </tr>
        </tbody>
      </table>

        {/* Supplier Table */}
        <table className="w-full border-collapse mb-4 text-sm">
        <thead>
          <tr className="bg-gray-200">
            <th className="border px-3 py-2">SN No.</th>
            <th className="border px-3 py-2">Description of Goods</th>
            <th className="border px-3 py-2">Quantity</th>
            <th className="border px-3 py-2">Rate</th>
            <th className="border px-3 py-2">Per</th>
            <th className="border px-3 py-2">Disc. %</th>
            <th className="border px-3 py-2">Taxable Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-3 py-2">1</td>
            <td className="border px-3 py-2">LJ</td>
            <td className="border px-3 py-2">100</td>
            <td className="border px-3 py-2">100.00</td>
            <td className="border px-3 py-2">-</td>
            <td className="border px-3 py-2">0%</td>
            <td className="border px-3 py-2">10,000.00</td>
          </tr>
          <tr>
            <td className="border px-3 py-2">2</td>
            <td className="border px-3 py-2"></td>
            <td className="border px-3 py-2">100</td>
            <td className="border px-3 py-2">100.00</td>
            <td className="border px-3 py-2">-</td>
            <td className="border px-3 py-2">0%</td>
            <td className="border px-3 py-2">10,000.00</td>
          </tr>
          <tr>
            <td colSpan="6" className="font-bold border px-3 py-2">Total</td>
            <td className="font-bold border px-3 py-2">₹20,000.00</td>
          </tr>
        </tbody>
      </table>

      {/* Footer */}
      <table className="w-full border-collapse mb-4 text-sm">
        <tbody>
          <tr>
            <td className="border px-3 py-2">
              Inspection Report: <span className="font-bold">Accepted</span>
            </td>
            <td className="border px-3 py-2">
              Payment Terms: <span className="font-bold">15 Days</span>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="font-bold">Remark:</div>

        {/* Signature Table */}
        <table className="w-full border-collapse text-sm">
        <tbody>
          <tr className="text-center font-bold bg-gray-200">
            <td className="border px-3 py-2">Prepared By Store</td>
            <td className="border px-3 py-2">Inspected By User</td>
            <td className="border px-3 py-2">Bill Processed By Authorized</td>
            <td className="border px-3 py-2">Audited By Authorized</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default FormatPdf;
