// AddParty.js
import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import api from "../Axios/api";
import PartyForm from "./AddPartyForm";

const AddParty = () => {

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [cityList, setCityList] = useState([]);
    const [editId, setEditId] = useState(null);

    const [inputs, setInputs] = useState({
        vendor_name: "",
        vendor_password: "",
        vendor_added_on: "",
        vendor_mobile: "",
        vendor_email: "",
        vendor_address: "",
        vendor_area: "",
        vendor_city: "",
        vendor_gstno: "",
    });

    const [append, setAppend] = useState({
        city: "",
    });

    const [itemsData, setItemsData] = useState([]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "sn",
                header: "SN",
                size: 50,
                Cell: ({ row }) => row.index + 1,
            },
            { accessorKey: "vendor_name", header: "Name" },
            { accessorKey: "vendor_mobile", header: "Mobile No.", size: 50 },
            { accessorKey: "vendor_city", header: "City" },
            { accessorKey: "vendor_area", header: "Area" },
            {
                accessorKey: "vendor_added_on",
                header: "Registration Date",
                Cell: ({ cell }) => {
                    const dateValue = cell.getValue();
                    return dateValue
                        ? new Date(dateValue).toLocaleDateString("en-GB")
                        : "--";
                },
                size: 50,
            },
            {
                accessorKey: "action",
                header: "Action",
                Cell: ({ row }) => (
                    <div className="flex space-x-2">
                        <button
                            className="text-blue-600 hover:underline"
                            onClick={() => handleEdit(row)}
                        >
                            Edit
                        </button>
                        <button
                            className="text-red-600 hover:underline"
                            onClick={() => handleDelete(row)}
                        >
                            Delete
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const getParties = async () => {
        try {
            const response = await api.get(
                `vendor/get`
            );
            console.log(response.data);
            setItemsData(response.data || []);
        } catch (error) {
            console.log(error);
        }
    };

    const addParty = async (e) => {
        e.preventDefault();
        try {
            if (inputs.vendor_name === "" ||
                inputs.vendor_added_on === "" ||
                inputs.vendor_address === "" ||
                inputs.vendor_area === "" ||
                inputs.vendor_city === "" ||
                inputs.vendor_gstno === "") {
                alert("Fill All Fields");
                return;
            }
            console.log("asefg");
            const response = await api.post(`/vendor/add`, inputs);
            const newItem = response.data.vendor;
            setItemsData((prevData) => [newItem, ...prevData]);
            return;
            resetHandler();
        } catch (error) {
            console.log(error);
        }
    };

    const editItem = async (e, id) => {
        e.preventDefault();
        try {
            const response = await api.patch(`/vendor/update/${id}`, inputs);
            console.log(response);
            resetHandler();
            getParties();
        } catch (error) {
            console.log(error);
        }
    };

    const handleEdit = (row) => {
        setEditId(row.original._id);
        setInputs({
            vendor_name: row.original.vendor_name,
            vendor_password: row.original.vendor_password,
            vendor_added_on: row.original.vendor_added_on,
            vendor_mobile: row.original.vendor_mobile,
            vendor_email: row.original.vendor_email,
            vendor_address: row.original.vendor_address,
            vendor_area: row.original.vendor_area,
            vendor_city: row.original.vendor_city,
            vendor_gstno: row.original.vendor_gstno,
        });
    };

    const handleDelete = async (row) => {
        const id = row.original._id;
        try {
            const response = await api.delete(`/vendor/delete/${id}`);
            getParties();
        } catch (error) {
            console.log(error);
        }
    };

    const resetHandler = () => {
        setInputs({
            vendor_name: "",
            vendor_password: "",
            vendor_added_on: "",
            vendor_mobile: "",
            vendor_email: "",
            vendor_address: "",
            vendor_area: "",
            vendor_city: "",
            vendor_gstno: "",
        });
        setEditId(null);
    };

    const fetchCities = async () => {
        try {
            const cityData = await api.get(`/vendor-city/get-cities`);
            setCityList(cityData.data);
        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    };

    useEffect(() => {
        getParties();
        fetchCities();
    }, [pagination]);

    return (
        <div className="flex flex-col relative">
            <div className="flex flex-col lg:flex-row gap-6 p-6 h-[700px]">
                <div className="bg-white p-6 rounded-lg shadow-md lg:flex-grow overflow-y-scroll scrollbar-none">
                    <h2 className="text-xl font-semibold mb-4">All Parties</h2>
                    <MaterialReactTable
                        columns={columns}
                        data={itemsData}
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10, 20, 50, 100],
                        }}
                        initialState={{ pagination: { pageSize: 10 } }}
                    />

                </div>
                <div className="h-[820px]">
                    <PartyForm
                        inputs={inputs}
                        setInputs={setInputs}
                        append={append}
                        setAppend={setAppend}
                        editId={editId}
                        citylist={cityList}
                        setCityList={setCityList}
                        addParty={addParty}
                        editItem={editItem}
                        resetHandler={resetHandler}
                    />
                </div>
            </div>
        </div>
    );
};

export default AddParty;