import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { useParams } from "react-router-dom";
import api from "./Axios/api";

const StockIssueCategoryItems = () => {
    const { id } = useParams();
    const [itemsData, setItemsData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [search, setSearch] = useState("");

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const getItems = async () => {
        try {
            const { pageIndex, pageSize } = pagination;
            const res = await api.get(`purchase-item/get-item/${id}/?limit=${pageSize}&page=${pageIndex + 1}&search=${search.length >= 3 ? search : ''}`);
            console.log("API Response:", res.data);

            setItemsData(res.data.items || []);
            setRowCount(res.data.totalResults || 0);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getItems();
    }, [pagination, search]);

    const columns = useMemo(() => [
        {
            accessorKey: "sn",
            header: "S.No",
            size: 50,
            Cell: ({ row }) => row.index + 1,
        },
        { accessorKey: "item_title", header: "Item Title" },
        { accessorKey: "item_rate", header: "Item Rate" },
        {
            accessorKey: "item_qty",
            header: "Item Quantity",
            Cell: ({ row }) => row.original.item_qty ?? 0,
        },
        { accessorKey: "item_branch_name", header: "Item Branch" },
    ], []);

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Stock Issue Items</h1>
                <input
                    type="text"
                    placeholder="Search..."
                    className="border px-3 py-2 rounded"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>

            <MaterialReactTable
                columns={columns}
                data={itemsData}
                manualPagination
                muiTablePaginationProps={{
                    rowsPerPageOptions: [10, 20, 50],
                }}
                state={{ pagination }}
                rowCount={rowCount}
                onPaginationChange={setPagination}
            />
        </div>
    );
};

export default StockIssueCategoryItems;